import { Injectable } from '@angular/core';
import { InsuranceProduct, InsuranceProductDuration, InsuranceProductType } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/models';
import { HttpClient } from '@angular/common/http';
import { NgSellyConfig } from 'ng-selly.config';
import * as _ from 'lodash';
import { take } from 'rxjs/internal/operators';

const SESSION_STORAGE_KEY = 'ProductListService.list';

@Injectable()
export class ProductListService {
  _list: Array<InsuranceProduct> = [];

  constructor(private http: HttpClient, private config: NgSellyConfig) {}

  public async getByCode(code: string): Promise<any> {
    if (this._list.length === 0) {
      return this.getList().then(() => this.getByCode(code));
    } else {
      let foundProduct = _.find(this._list, (product: InsuranceProduct) => product.code === code);
      return Promise.resolve(foundProduct);
    }
  }

  public getList(): Promise<Array<InsuranceProduct>> {
    console.log('[ProductListService][getList]');
    let data = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (!_.isNil(data)) {
      this._list = JSON.parse(data);
      return Promise.resolve(this._list);
    }

    return new Promise<Array<InsuranceProduct>>((resolve, reject) => {
      this.http.get<Array<InsuranceProduct>>(`${this.config.apiServer}api/prodotti`, {
        withCredentials: true,
      }).pipe(take(1)).subscribe((productList: Array<InsuranceProduct>) => {
        productList = _.sortBy(productList, [(product: InsuranceProduct) => product.weight]);

        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(productList));

        this._list = productList;
        resolve(this._list);
      });
    });
  }
}


@Injectable()
export class ProductListMockService extends ProductListService {

  public async getByCode(code: string): Promise<InsuranceProduct> {
    if (this._list.length === 0)
      await this.getList();

    return Promise.resolve(this._list.find((e: InsuranceProduct) => {
      if (e.code === code)
        return true;
      }));
  }

  public getList(): Promise<Array<InsuranceProduct>> {
    console.log('[ProductListMockService][getList]');
    if (this._list.length > 0)
      return Promise.resolve(this._list);

    this._list = [{
      'helvetiaLink': '',
      'maxDuration': 100,
      'maxiumCoverageAge': 74,
      'greetings': 'Buon viaggio con Helvetia!',
      'reasonWhy': ['Assistenza diretta durante tutto il viaggio e contemporaneamente anche per i familiari rimasti a casa!',
        'Rimborso in caso di annullamento viaggio!',
        'Mis-connection... Hai perso la coincidenza aerea? Nessun problema...Ti facciamo raggiungere lo stesso la destinazione del tuo viaggio!!'],
      'productType': InsuranceProductType.PRODUCT,
      'type': InsuranceProductDuration.TEMPORARY,
      'iconName': 'EASYTRAVEL',
      'weight': 2,
      'code': 'EASYTRAVEL',
      'name': 'Helvetia Ok Travel',
      'description': 'Ok Travel la polizza che ti porta in viaggio. <br> Contratto di assicurazione multirischi del viaggiatore.',
      'status': true,
      'date_from': 1523884750198,
      'date_to': null
    }, {
      'helvetiaLink': '',
      'maxDuration': 28,
      'maxiumCoverageAge': 70,
      'greetings': 'Buona sciata con Helvetia!',
      'reasonWhy': ['Perché prevede le spese di soccorso toboga, ambulanza ed elicottero, con la possibilità di consultare un medico.',
        'Perché sono comprese tutte le spese di cura da infortunio.',
        "Perché con un'unica polizza puoi assicurare anche i tuoi famigliari."],
      'productType': InsuranceProductType.PRODUCT,
      'type': InsuranceProductDuration.TEMPORARY,
      'iconName': 'EASYSKI',
      'weight': 1,
      'code': 'EASYSKI-USER-2',
      'name': 'Helvetia Easy Ski',
      'description': 'Easy Ski la polizza per te sugli sci. <br> Contratto di assicurazione multirischi dello sciatore.',
      'status': true,
      'date_from': 1523884750198,
      'date_to': null
    }];

    return Promise.resolve(this._list);
  }
}
