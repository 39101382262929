import { FileUploadService, UploadErrorEvent, UploadSuccessEvent } from '@helvetia/ng-oscommon/src/lib/multi-file-upload/file-upload.service';
import { FileUploadOptions } from '@helvetia/ng-oscommon/src/lib/ng-oscommon';

// @ts-ignore
export class FileUploadWithCredentialService extends FileUploadService {

  private opts: FileUploadOptions;

  uploadFiles() {
    if (this.queue.length === 0) {
      return;
    }

    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    let payload = new FormData();

    this.queue.forEach(file => {
      let encoded = encodeURIComponent(file.name);
      payload.append(this.opts.paramName, file, encoded);
    });

    xhr.onabort = () => {
      this.onUploadError.emit(new UploadErrorEvent(xhr));
      this.onUploadingFinished.emit(null);
    };

    xhr.onerror = () => {
      this.onUploadError.emit(new UploadErrorEvent(xhr));
      this.onUploadingFinished.emit(null);
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        this.onUploadSuccess.emit(new UploadSuccessEvent(xhr));
        this.queue = [];
      } else {
        this.onUploadError.emit(new UploadErrorEvent(xhr));
      }
      this.onUploadingFinished.emit(null);
    };

    xhr.open(this.opts.method, this.opts.url, true);
    if (this.opts.headers) {
      Object.keys(this.opts.headers).forEach(key => {
        xhr.setRequestHeader(key, this.opts.headers[key]);
      });
    }

    this.onUploading.emit(null);
    xhr.send(payload);
  }
}
