import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, Data, ActivatedRoute } from '@angular/router';
import { UserService, UserState } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/auth/user.service';
import { NgSellyConfig } from 'ng-selly.config';
import { FEATURES } from '../config/ng-selly-base.config';
import { SpinnerConfig, SpinnerController, SpinnerStatus } from '@helvetia-italia/ng-selly-components/src/lib/spinner-controller/spinner.controller';
import { Subscription } from 'rxjs';
import { SupportScriptsService } from '../support-scripts/support-scripts.service';
import { InfoBoxConfig, InfoBoxController } from '../custom-component-module/infobox.controller';


@Component({
  selector: 'selly-dashboard',
  template: require('./selly-dashboard.html'),
  styles: [require('./selly-dashboard.scss')],
})
export class SellyDashboardComponent implements OnInit {

  data;
  showSpinner: boolean = false;
  textSpinner: string;
  features = FEATURES;
  user;
  scriptId;

  private footerSubscription: Subscription;
  private currentUrlRoot: string;

  @ViewChild('soInfoBox', {static: true})
  infobox: any;
  infoBoxText: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private config: NgSellyConfig,
    private supportScriptsService: SupportScriptsService,
    // private saveForLate: SaveForLateService,
    // viewContainerRef: ViewContainerRef,
    activatedRoute: ActivatedRoute,
    spinnerController: SpinnerController,
    infoBoxController: InfoBoxController) {

    // saveForLate.setRootViewContainerRef(viewContainerRef);
    // setTimeout(() => {
    //   saveForLate.checkForSavedData();
    // }, 1000);

    activatedRoute.data.subscribe((data: Data) => {
      this.currentUrlRoot = data.urlRoot;
    });
    let spinnerOpened = 0;
    spinnerController.subscribe().subscribe((spinnerStatus: SpinnerConfig) => {
      if (spinnerStatus.status === SpinnerStatus.OPEN) {
        spinnerOpened++;
        this.showSpinner = true;
        this.textSpinner = spinnerStatus.text;
      } else {
        spinnerOpened--;
        if (spinnerOpened === 0)
          this.showSpinner = false;
      }
    });
    infoBoxController.change.subscribe((infoBoxConfig: InfoBoxConfig) => {
      if (infoBoxConfig.title) this.infobox.title = infoBoxConfig.title;

      this.infoBoxText = infoBoxConfig.html;
      this.infobox.open();
    });
  }

  ngOnInit() {

    // this.supportScriptsService.sharedScriptId.subscribe(scriptId => this.scriptId = scriptId);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  logout() {
    sessionStorage.clear();
    location.reload();
  }

  ngOnDestroy(): void {
    this.footerSubscription.unsubscribe();
  }
}
