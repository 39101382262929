import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { daily, weekly, monthly, fromBeginningtoToday } from '../data/utils';
import { NgSellyConfig } from 'ng-selly.config';
import { BehaviorSubject } from 'rxjs';

const moment = require('moment');
const configClass: any = new NgSellyConfig();

@Injectable()
export class SupportScriptsService {
  USER_PART;
  USER_API;

  // private static setHeadersAuth() {
  //   const clientId = '1aa42453-3f3e-4b7b-8174-08528c2e586c';
  //   const clientSecret = 'OTAxMThjMTktZTY4Ni00NmVlLWEwZjQtNTJjZTFiMGUzOWUw';
  //   let encodedData = window.btoa(clientId + ':' + clientSecret);
  //   // const authorizationHeaderString = 'Authorization: Basic ' + encodedData;
  //   // const token = localStorage.getItem(AuthConstants.CURRENT_TOKEN);
  //   return new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     'Authorization': 'Basic ' + encodedData
  //   });
  // }
  //
  // private static setHeaders() {
  //   return new HttpHeaders({
  //     'Anonymous': 'Not'
  //   });
  // }

  // private static setHeadersProducts() {
  //   return new HttpHeaders({
  //     'Anonymous': 'Not',
  //     secret: '5af701b6-4f11-414e-bb2f-d23c04c40358'
  //   });
  // }

  constructor(private httpClient: HttpClient,
              @Inject('env') private env) {
    this.USER_PART = 'api/admin/users';
    this.USER_API = env.apiServer + this.USER_PART;
  }

  run_report(reportId, dFromVal, dToVal, env) {

    let hasError = false;
    console.log(dFromVal, dToVal);

    let dFrom = moment(dFromVal, 'DD-MM-YYYY').toDate();
    let dTo = moment(dToVal, 'DD-MM-YYYY').toDate();

    if (dTo < dFrom) {
      document.getElementById('to-error').classList.add('uxa-state-a-validation-feedback--visible');
      document.getElementById('to-error span').innerHTML = 'La data di Fine non può essere precedente a quella di Inizio';
      hasError = true;
    } else {
      document.getElementById('to-error').classList.remove('uxc-state-a-validation-feedback--visible');
    }

    let periodType = dFromVal + ';' + dToVal; // $('input[name=period]:checked').val();
    // console.log('from', $('#from').val());
    // console.log('to', $('#to').val());

    // call controller with rest api ajax
    if (!hasError) {
      document.getElementById('btn-export').style.display = 'none';
      // document.getElementsByClassName('loader')[0].style.display = 'block';
      //
      // let userBtnEnvVal = document.getElementsByName('env');
      // var i;
      // for (i = 0; i < userBtnEnvVal.length; i++) {
      //   if (userBtnEnvVal[i].checked) {
      //     userBtnEnvVal[i].checked = true;
      //   }
      // }
      // const userBtnEnvVal = document.querySelector('input[name=env]:checked');
      // console.log(userBtnEnvVal);
      // log out the value from the :checked radio


      // console.log('userBtnEnvVal:' + userBtnEnvVal);
      //

      let url = '/execReport/' + reportId + '/' + periodType + (env !== undefined ? '/' + env : '');
      return this.httpClient.get<any>(
        configClass.apiReports + url,
        {
          observe: 'response',
          // headers: SupportScriptsService.setHeaders(),
          withCredentials: true
        })
        .subscribe((response) => {
          if (response) {
            if (response.status === 200) {
              console.log(response);
            } else {
              // await sendToSlack({title: 'statistics', msg: `statisticsApi status ${result.status}`, level: SLACK_STATUS.DANGER});
              console.log(response);
            }
          }
        }, (errorResponse: HttpErrorResponse) => {
          console.log('error ', errorResponse);
          // this.infoStatus = errorResponse.name;
          // this.infoMessage = errorResponse.message;
        });
    }

    // jQuery.ajax({
    //   dataType: 'json',
    //   contentType: 'application/json; charset=utf-8',
    //   method: 'GET',
    //   url: '/execReport/' + reportId + '/' + periodType + (userBtnEnvVal !== undefined ? '/' + userBtnEnvVal : ''),
    //   beforeSend: function (xhr) {
    //     // xhr.setRequestHeader('secret', token);
    //   },
    //   success: function (result) {
    //     jQuery('#htmlResult').html(result.msg);
    //     // show button to export
    //     jQuery('#btn-export').show();
    //     jQuery('.loader').hide();
    //   },
    //   error: function (err) {
    //     console.log('error RUN REPORT', err);
    //     jQuery('.loader').hide();
    //   }
    // });
  }

  showPeriodText(periodType) {
    let p = this.retrieveFilterTypeCmd(periodType);
    let html = `<span style=\"font-size: 0.9rem;\">Period: jQuery{p.from} - jQuery{p.to}</span>`;
    jQuery('#text-period').html(html);
  }

  retrieveFilterTypeCmd(period) {
    const props = {
      period_type: {
        DAILY: 'DAILY',
        WEEKLY: 'WEEKLY',
        MONTHLY: 'MONTHLY',
        TO_TODAY: 'TOTODAY'
      }
    };

    if (period === undefined || period === null)
      return daily();
    else if (period.toUpperCase() === props.period_type.DAILY)
      return daily();
    else if (period.toUpperCase() === props.period_type.WEEKLY)
      return weekly();
    else if (period.toUpperCase() === props.period_type.MONTHLY)
      return monthly();
    else if (period.toUpperCase() === props.period_type.TO_TODAY)
      return fromBeginningtoToday();
    return null;
  }

  getScriptsUsers(active?: boolean, query?: string, email?: string, startIndex?: number, displayName?: string, userName?: string) {
    let url = this.USER_API;
    if (active) {
      url = url.concat(`?active=${active}`);
    } else if (query) {
      url = url.concat(`?query=${query}`);
    } else if (email) {
      url = url.concat(`?email=${email}`);
    } else if (startIndex) {
      url = url.concat(`?startIndex=${startIndex}`);
    } else if (displayName) {
      url = url.concat(`?displayName=${displayName}`);
    } else if (userName) {
      url = url.concat(`?userName=${userName}`);
    }

    return this.httpClient.get<any>(url, {observe: 'response', withCredentials: true});
  }

  userDetail(id: string) {
    let url = this.USER_API;
    url = url.concat(`/${id}/profile`);
    return this.httpClient.get<any>(url, {observe: 'response', withCredentials: true});

  }


  userDetailPost(postData) {
    let url = this.USER_API;
    return this.httpClient.post<any>(url, postData, {observe: 'response', withCredentials: true});

  }

  userProfileEdit(sub: string, postData) {
    let url = this.USER_API;
    url = url.concat(`/${sub}/profile`);
    return this.httpClient.put<any>(url, postData, {observe: 'response', withCredentials: true});

  }

  getUserProducts() {
    return this.httpClient.get<any>(configClass.config().apiServer + '/api/prodotti', {observe: 'response', withCredentials: true});

  }

  uploadCsv(postData) {
    return this.httpClient.post(configClass.config().apiServer + 'api/uploadWinnerMarketingCampaign', postData,  { responseType: 'blob', observe: 'response', withCredentials: true});

  }

  // nextMessage(scriptId: string) {
  //   this.scriptId.next(scriptId);
  // }
}

