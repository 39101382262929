import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class HeadlineService {
  private description: ReplaySubject<string> = new ReplaySubject<string>(1);

  setValue(value: string) {
    this.description.next(value);
  }

  getObserver() {
    return this.description.asObservable();
  }
}
