import { ApplicationRef, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SellyDashboardRoutes } from './selly-dashboard.routes';
import { NgSellyConfig } from 'ng-selly.config';
import { ApiConfigService } from './api-config.service';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { UxBaseModule } from '@helvetia/ng-ux-base/index';
import { NG_UX_BASE_API_CONFIG } from '@helvetia/ng-ux-base/src/lib/ng-ux-base';
import { NG_OSCOMMON_API_CONFIG } from '@helvetia/ng-oscommon/src/lib/ng-oscommon.ts';
import localeIt from '@angular/common/locales/it';
import localeExraIt from '@angular/common/locales/extra/it';
import { SellyDashboardComponent } from './selly-dashboard/selly-dashboard.component';
import { OsCommonModule } from '@helvetia/ng-oscommon';
import { SharedModule } from '@helvetia/ng-ux-base/src/demo-app/shared/shared.module';
import { NgSellyDashboardServicesModule } from '@helvetia-italia/ng-selly-lib-operator-dashboard';
import { AuthComponent, AuthSecurityComponent, LoginComponent } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/auth/user.service';
import { AgentsGuard, LiquidatoreGuard, AffinityOperatorGuard, ScriptsGuard } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/auth/agents.guard';
import { HeaderComponent } from './selly-dashboard/header/header.component';
import { HeadlineService } from './remote-service-module/headline/headline.service';
import { ProductListService } from './remote-service-module/product-list/product-list.mock-service';
import { PolicyListService } from './remote-service-module/policy-list/policy-list.mock-service';
import { SpinnerController } from '@helvetia-italia/ng-selly-components/src/lib/spinner-controller/spinner.controller';
import { ModalConfirmModule } from '@helvetia-italia/ng-selly-components/src/lib/modal-confirm/modal-confirm.module';
import { Overlay } from '@angular/cdk/overlay';
import { FileUploadService } from '@helvetia/ng-oscommon/src/lib/multi-file-upload/file-upload.service';
import { ApiUploadVouchersService } from './api-upload-vouchers.service';
import { LoaderModule } from './selly-dashboard/loader/loader.module';
import { EditMailTemplateComponent } from './edit-mail-template/edit-mail-template.component';
import { AffinityUploadOctoFileComponent } from './affinity-upload-octo-file/affinity-upload-octo-file.component';
import { InfoBoxController } from './custom-component-module/infobox.controller';
import { SupportScriptsService } from './support-scripts/support-scripts.service';
import { HiScreenOverlayModule } from '@helvetia-italia/ng-selly-components/src/lib/screen-overlay-component/hi-screen-overlay.module';
import { IconModule } from './custom-component-module/icon/icon.module';
import { AffinityQuotesService } from './affinity-quotes/affinity-quotes.service';
import { AffinityOctoUploadModule } from './affinity-upload-octo-file/affinity-octo-upload.module';

const configClass: any = new NgSellyConfig();

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UxBaseModule.forRoot(),
    OsCommonModule.forRoot(),
    SellyDashboardRoutes,
    SharedModule,
    NgSellyDashboardServicesModule.forRoot(configClass.config()),
    HttpClientModule,
    ModalConfirmModule,
    LoaderModule,
    HiScreenOverlayModule,
    IconModule,
    AffinityOctoUploadModule
  ],
  declarations: [
    SellyDashboardComponent,
    HeaderComponent,
  ],
  entryComponents: [
    SellyDashboardComponent,
    AuthSecurityComponent,
    LoginComponent,
    AuthComponent,
  ],
  providers: [
    HttpClientModule,
    ApiConfigService,
    NgSellyDashboardServicesModule,
    AgentsGuard,
    LiquidatoreGuard,
    AffinityOperatorGuard,
    ScriptsGuard,
    HeadlineService,
    ProductListService,
    PolicyListService,
    NgSellyConfig,
    SpinnerController,
    Overlay,
    FileUploadService,
    ApiUploadVouchersService,
    InfoBoxController,
    SupportScriptsService,
    AffinityQuotesService,
    {provide: LOCALE_ID, useValue: 'it'},
    {provide: NG_UX_BASE_API_CONFIG, useClass: ApiConfigService},
    {provide: NG_OSCOMMON_API_CONFIG, useClass: ApiConfigService},
  ]
})
export class SellyAppModule {
  constructor(private _appRef: ApplicationRef, router: Router) {
    localeIt[14][2] = '¤ #,##0.00';
    registerLocaleData(localeIt, 'it', localeExraIt);
  }

  ngDoBootstrap() {
    this._appRef.bootstrap(SellyDashboardComponent);
  }
}
