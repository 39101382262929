import { Injectable } from '@angular/core';

@Injectable()
export class ApiConfigService {
  assetsPath: string = null;
  stageUrl: string = null;

  constructor() {
    this.assetsPath = 'assets/img';
    this.stageUrl = 'http://localhost:3000/';
  }
}
