import { NgModule } from '@angular/core';
import { UxBaseModule } from '@helvetia/ng-ux-base/index';
import { OsCommonModule } from '@helvetia/ng-oscommon/index';
import { AffinityOctoUploadRouting } from './affinity-octo-upload.routing';
import { HttpClientModule } from '@angular/common/http';
import { GetUsersService } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/hi-get-user-dashboard/get-users.service';
import { DatePipe } from '@angular/common';
import { NgSellyCustomComponentModule } from '@helvetia-italia/ng-selly-components/src/lib/ng-selly-custom-components';
import { AffinityUploadOctoFileComponent } from './affinity-upload-octo-file.component';
import { LoaderModule } from '../selly-dashboard/loader/loader.module';

@NgModule({
  imports: [
    AffinityOctoUploadRouting,
    UxBaseModule.forRoot(),
    OsCommonModule.forRoot(),
    NgSellyCustomComponentModule.forRoot(),
    LoaderModule
  ],
  declarations: [
    AffinityUploadOctoFileComponent
  ],
  exports: [  ],
  providers: [
    HttpClientModule,
    GetUsersService,
    DatePipe
  ]
})
export class AffinityOctoUploadModule {
  constructor() {}
}
