import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgSellyConfig } from 'ng-selly.config';
import { timeout, timeoutWith } from 'rxjs/operators';

const configClass: any = new NgSellyConfig();

@Injectable()
export class ApiUploadVouchersService {


  constructor(
    private client: HttpClient
  ) {
  }

  public uploadVocuhers(data: FormData) {
    return this.client.post<any>(configClass.config().apiServer + 'api/vouchers', data, { observe: 'response', withCredentials: true
    });
  }
}
