export interface Guarantee {
  showReadMore?: boolean;
  sellable?: number;
  infoBox?: string;
  disabled?: boolean;
  weight: number;
  code: string;
  name: string;
  description: string;
  requires: any[];
  excludes: any[];
  clauses: {
    [clauseCode: string]: ClauseMap
  };
  optional: boolean;
  dataInput?: any;
  parent?: string;
}
export interface ClauseMap {
  [code: string]: Clause;
}
export interface Clause {
  'type': 'MASSIMALE' | 'LIMITAZIONE';
  'code': string;
  'description': string;
  'origDescription'?: string;
  'name': string;
  'value': string;
  'factor': number;
}

export const guaranteeListVehicle: Array<Guarantee> = [
  {
    'weight': 1,
    'code': '#AFFINITY-AUTO-RCA',
    'name': 'Responsabilità Civile Auto',
    // tslint:disable-next-line:max-line-length
    'description': "Sono assicurati tutti i danni procurati a terzi o cose di terzi durante <b>la circolazione stradale</b> (anche in aree private, escluse le zone aereoportuali) qualsiasi sia il conducente del veicolo (anche un neopatentato) ed il kilometraggio (illimitato). Sono inoltre risarcibili i danni causati:\n<ul><li>da passeggeri a terze persone (ad es. un passeggero che apre la portiera e provoca danni a terzi);</li><li>con il limite di € 250.000 a mobili e immobili di terzi dall'incendio del veicolo anche quando non è in circolazione (ad es. il veicolo incendia nel garage e le fiamme danneggiano altri veicoli vicini);</li><li>da conducenti con patente idonea ma scaduta o causati da figli minori (salvo ciclomotori);</li><li>in caso di omesso o errato utilizzo delle cinture di sicurezza e/o dei sistemi di ritenuta dei bambini la garanzia RCA è sempre valida;</li></ul><br><b>In caso di guida eccezzionalmente in stato di ebbrezza la rivalza viene limitata ad € 500.</b><br><b>La garanzia contempla anche l'estensione Danni patrimoniali puri, con un massimale annuo di € 20.000</b>",
    'requires': [],
    'excludes': [],
    'clauses': {
      'AFFINITY-AUTO-RCA-TIPO-GUIDA': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'Tipo Guida',
          'description': 'Libera',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'Tipo Guida',
          'description': 'Esperta',
          'value': '1',
          'factor': 1
        },
        '3': {
          'type': 'LIMITAZIONE',
          'code': '3',
          'name': 'Tipo Guida',
          'description': 'Esclusiva',
          'value': '3',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-GANCIO-TRAINO': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'Gancio Traino',
          'description': 'No',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'Gancio Traino',
          'description': 'Sì',
          'value': '1',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-RINUNCIA-RIVALSA-EBREZZA': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'RIvalsa In Caso di Ebrezza',
          'description': 'No',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'RIvalsa In Caso di Ebrezza',
          'description': 'Sì',
          'value': '1',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-MASSIMALE': {
        '2019120101': {
          'type': 'MASSIMALE',
          'code': '2019120101',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': 'MINIMO DI LEGGE',
          'factor': 1,
          'origDescription': '01,22/ 06,07/ 07,29'
        },
        '2019120102': {
          'type': 'MASSIMALE',
          'code': '2019120102',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 8.500.000 UNICO',
          'factor': 1,
          'origDescription': '08,50/ 08,50/ 08,50'
        },
        '2019120103': {
          'type': 'MASSIMALE',
          'code': '2019120103',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 10.000.000 UNICO',
          'factor': 1,
          'origDescription': '10,00/ 10,00/ 10,00'
        },
        '2019120104': {
          'type': 'MASSIMALE',
          'code': '2019120104',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 15.000.000 UNICO',
          'factor': 1,
          'origDescription': '15,00/ 15,00/ 15,00'
        },
        '2019120105': {
          'type': 'MASSIMALE',
          'code': '2019120105',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 25.000.000 UNICO',
          'factor': 1,
          'origDescription': '25,00/ 25,00/ 25,00'
        },
        '2019120106': {
          'type': 'MASSIMALE',
          'code': '2019120106',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 50.000.000 UNICO',
          'factor': 1,
          'origDescription': '50,00/ 50,00/ 50,00'
        }
      }
    },
    'optional': false,
    'dataInput': [
      {
        'type': 'NUMBER',
        'variableName': 'VALORE_VIAGGIO',
        'label': 'Valore del viaggio in €uro',
        'defaultValue': '100',
        'check': {
          'min': 1,
          'max': 10000
        }
      },
      {
        'type': 'DATE',
        'variableName': 'DATA_ACQUISTO',
        'label': 'Data di acquisto',
        'defaultValue': '26.03.2020',
        'check': {
          'min': '25.03.2020',
          'max': '26.03.2020'
        }
      }
    ],
    'showReadMore': true
  },
  {
    'weight': 2,
    'code': 'FURIN',
    'name': 'Furto e Incendio',
    'description': `Prevede un indennizzo per i danni subiti dal veicolo in seguito a sviluppo di fiamma, esplosione, scoppio e azione del fulmine, furto (consumato o tentato) e rapina.<br>La garanzia "Incendio e Furto" può essere personalizzata con una o più delle seguenti condizioni complementari:`,
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 1,
    'code': 'NATUR',
    'name': 'Fenomeni Naturali',
    'description': `Prevede il rimborso dei danni subiti dal veicolo a causa di fenomeni atmosferici e tellurici nel caso in cui non sia stato dichiarato lo stato di calamità naturale dalle competenti autorità.`,
    'infoBox': `Prevista solo per autovetture e autocarri/camper. Sono garantiti i danni derivanti da: eventi atmosferici, grandine, alluvioni, trombe d’aria, terremoto, ecc.<br>
    <div class='uxa-h-spacer--s'></div>
    <strong>SCOPERTO:</strong> 10% del danno con MINIMO di € 250,00<br>
    <div class='uxa-h-spacer--s'></div>
    Il premio viene calcolato, in base alla provincia di residenza, alla cilindrata e alla vetustà del veicolo.
    `,
    'parent': 'FURIN',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 1,
    'code': 'SOCIO',
    'name': 'Eventi socio politici e vandalismo',
    'description': `Prevede il rimborso dei danni subiti dal veicolo a causa di tumulti popolari, scioperi, sommosse, atti di terrorismo, sabotaggio e vandalismo.`,
    'infoBox': `
    Prevista solo per autovetture e autocarri/camper. Sono garantiti i danni derivanti da:<br>
    <div class='uxa-h-spacer--s'></div>
    <strong>EVENTI SOCIO POLITICI</strong>: manifestazioni, scioperi, tumulti, sommosse, ecc;<br>
    <div class='uxa-h-spacer--s'></div>
    <strong>ATTI VANDALICI</strong>: per danni provocati da atti di vandalismo o comunque dolosi causati da terzi.<br>
    <div class='uxa-h-spacer--s'></div>
    <strong>SCOPERTO</strong>: 10% del danno con MINIMO di € 250,00<br>
    <div class='uxa-h-spacer--s'></div>
    Il premio viene calcolato, in base alla provincia di residenza ed alla cilindrata e vetustà del veicolo.<br>
        `,
    'parent': 'FURIN',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 1,
    'code': 'EVENT',
    'name': 'Pack eventi',
    'description': `Prevede un insieme di garanzie accessorie, con i rispettivi limiti di indennizzo su base annua e per evento, per completare la gamma dei servizi prestati dalla Compagnia in caso di sinistro.`,
    'infoBox': `Previsto solo per autovetture e autocarri/camper. Sono garantiti i danni derivanti dalle seguenti ipotesi, con rispettivi limiti di indennizzo su base annua e per evento (salvo il caso indicato con *, che deve intendersi esclusivamente per un evento nell'annualità assicurativa):
    <div class='uxa-h-spacer--s'></div>
    <div class='row'>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Duplicazione Patente</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Ripristino Danni Incendio Box</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 3000</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Custodia-Traino-Parcheggio</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Spese Di Immatricolazione</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 500</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Danni Meccanici Da Circ. Abusiva</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 1000</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Danni Ai Bagagli</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Ripristino Antifurto</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250 *</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Lavaggio E Disinfezione Veicolo</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Trasporto Su Treni O Traghetti</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 3000</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Tassa Automobilistica</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Trasporto Vittime Della Strada</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Fenomeno Elettrico</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 250</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Collisione Veicolo Non Identificato O Non Assicurato</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 1000</div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="col-xs-9 col-sm-10 col-md-8 col-lg-8">Urto Contro Animali Selvatici, Pedoni O Ciclisti</div>
        <div class="col-xs-3 col-sm-2 col-md-4 col-lg-4">€ 1000</div>
      </div>
    </div>`,
    'parent': 'FURIN',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 3,
    'sellable': 5,
    'code': '#AFFINITY-AUTO-KASKO',
    'name': 'Garanzie Kasko',
    'description': 'Prevista solo per autovetture e autocarri/camper. <b>Acquistabile esclusivamente in presenza di garanzia RCA e Furto e Incendio</b>',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true
  },
  {
    'weight': 1,
    'sellable': 5,
    'code': 'MAXIK',
    'name': 'Maxi Kasko',
    'description': 'Prevede un indennizzo per i danni subiti dal veicolo in seguito a urto, ribaltamento, uscita di strada o collisione durante la circolazione.',
    'infoBox': 'Garantisce il rimborso dei danni al veicolo a seguito di urto, collisione, ribaltamento, uscita di strada verificatesi durante la circolazione;',
    'parent': '#AFFINITY-AUTO-KASKO',
    'requires': [
      'FURIN'
    ],
    'excludes': [
      'SEMPK',
      'EASYK'
    ],
    'clauses': {},
    'optional': true
  },
  {
    'weight': 2,
    'sellable': 5,
    'code': 'SEMPK',
    'name': 'Sempre Ragione',
    'description': 'Prevede un indennizzo per i danni subiti dal veicolo in seguito a collisione con un altro veicolo targato o con un ciclomotore, purchéidentificato.',
    'infoBox': "E' una garanzia che prevede un indennizzo in caso di: collisione con altri veicoli identificati, urto con animali selvatici/randagi e/o pedoni e ciclisti purché avvenuti durante la circolazione;",
    'parent': '#AFFINITY-AUTO-KASKO',
    'requires': [
      'FURIN'
    ],
    'excludes': [
      'MAXIK',
      'EASYK'
    ],
    'clauses': {},
    'optional': true
  },
  {
    'weight': 3,
    'sellable': 5,
    'code': 'EASYK',
    'name': 'Sempre Ragione Easy',
    'description': 'Prevede - nella forma a primo rischio assoluto entro il limite di € 3.000,00 - un indennizzo per i danni subiti dal veicolo in seguito a collisione con altri veicoli identificati.',
    'infoBox': `
    Garantisce - nella forma a primo rischio assoluto entro il limite di € 3.000,00 - il rimborso dei danni al veicolo in caso di: collisione con altri veicoli identificati, urto con animali selvatici o
randagi e/o pedoni e ciclisti purché avvenuti durante la circolazione. <br>
<div class="uxa-h-spacer--s"></div>
<strong>
Il premio delle garanzie viene calcolato sulla base del premio della garanzia RCA (quindi sulla base della classe B/M dell’Assicurato e della sinistrosità) e di un coefficiente
legato al valore commerciale del veicolo.
</strong>
<div class="uxa-h-spacer--s"></div>
<strong>SCOPERTO:</strong> 10% del danno con MINIMO di € 250,00
<div class="uxa-h-spacer--s"></div>
<strong>LIMITI E NORME ASSUNTIVE:</strong> Non si possono emettere nuovi contratti per veicoli di valore commerciale inferiore a € 5.000 o superiore a € 70.000 (riduzione al 70% per le provincie
della Campania, Puglia e Sicilia) o  immatricolate al P.R.A. da oltre 5 anni. La garanzia non può essere concessa su polizze a tariffa Bonus/ Malus in classe di merito superiore alla 14.
`,
    'parent': '#AFFINITY-AUTO-KASKO',
    'requires': [
      'FURIN'
    ],
    'excludes': [
      'MAXIK',
      'SEMPK'
    ],
    'clauses': {},
    'optional': true
  },
  {
    'weight': 4,
    'code': 'CRIST',
    'name': 'Cristalli',
    'infoBox': 'Prevede il rimborso delle spese per la sostituzione o la riparazione del parabrezza, lunotto posteriore, cristalli laterali, tetto apribile o panoramico.    ',
    'description': 'Prevede il rimborso delle spese per la sostituzione o la riparazione del parabrezza, lunotto posteriore, cristalli laterali, tetto apribile o panoramico.',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': false
  },
  {
    'weight': 5,
    'code': 'LEGAL',
    'name': 'Tutela Legale',
    'description': 'Prevede il pagamento, in sede giudiziale e stragiudiziale, delle spese legali che potrebbero essere necessarie per la tutela degli interessi dell’Assicurato per fatti inerenti la circolazione. Le prestazioni vengono erogate da D.A.S. Difesa Automobilistica Sinistri S.p.A.',
    // tslint:disable-next-line:max-line-length
    'infoBox': "Valida solo per autovetture - motocicli - ciclomotori - veicoli diversi: <strong>entro il massimale di euro 30.000,00</strong> garantisce un servizio di assistenza legale (sia civile che penale) in caso di controversie derivanti da sinistro stradale e più precisamente:<div class='uxa-h-spacer--m'></div><ul class='uxa-m-list'><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di assistenza stragiudiziale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>gli oneri per l’intervento di un legale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>gli oneri per l’intervento di un perito;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di giustizia, in caso di condanna legale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese liquidate dalla controparte in caso di soccombenza;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese eventualmente dovute alla controparte nel caso di transazione autorizzata dalla Direzione Generale DAS;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di indagini per la ricerca di prove a difesa;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese per la remissione di denunce, querele, istanze alla Autorità Giudiziaria.</div></li></ul>",
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 6,
    'code': 'INFCO',
    // tslint:disable-next-line:max-line-length
    'infoBox': "Valida solo per autovetture, motocicli, ciclomotori e veicoli diversi: <strong>entro il massimale di euro 80.000,00</strong> garantisce un indennizzo al conducente del veicolo assicurato nel caso di morte o invalidità permanente a seguito di un incidente stradale; sono in garanzia anche gli infortuni (alla guida del veicolo assicurato) derivanti da: <div class='uxa-h-spacer--m'></div><ul class='uxa-m-list'><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>asfissia non di origine morbosa, le lesioni riportate in conseguenza della inspirazione di gas e di vapori; </div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>colpi di sole o di calore e annegamento;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>assideramento o congelamento e folgorazione;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>stato di malore o incoscienza o infarto miocardico o cerebrale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>imperizia, negligenza o imprudenza  anche grave;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>caduta di rocce, pietre, alberi e simili, nonché da valanghe o slavine.</div></li></ul><div class='uxa-h-spacer--xxl'></div><strong>La liquidazione dei danni, in caso di invalidità permanente, è gravata da franchigia pari a 3 punti percentuali</strong>",
    'name': 'Infortuni del conducente',
    'description': 'Prevede un indennizzo per gli infortuni che può subire il conducente quando si trova alla guida del veicolo assicurato oppure quando scende o sale dal veicolo, carica o scarica bagagli o effettua controlli o riparazioni di guasti.',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 7,
    'code': 'VOLAS',
    'name': 'Assistenza Stradale',
    'description': 'Garantisce numerosi servizi al veicolo ed alla persona in caso di emergenza conseguente ad incidente stradale, incendio, guasto meccanico, furto o rapina. Le prestazioni vengono erogate da Europ Assistance Italia S.p.A.',
    'infoBox': `Valida solo per autovetture - motocicli - autocarri/camper (con un peso complessivo a pieno carico pari o inferiore a 3,5 t).<br>
    Garantisce numerosi servizi al veicolo ed alla persona in caso di emergenza conseguente ad incendio, incidente stradale, guasto, furto, foratura pneumatico, smarrimento chiavi o mancanza carburante. Le prestazioni erogate da Europ Assistance vengono divise in tre macro classi: senza franchigia chilometrica, fuori dal comune di residenza e all'estero. <br><br>

    <div class='uxa-h-spacer--s'></div>
    <strong>SENZA FRANCHIGIA CHILOMETRICA:</strong>
    <div class='uxa-h-spacer--s'></div>
    <ul class='uxa-m-list'>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio di un carro attrezzi e/o officina mobile;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>recupero del veicolo fuori strada;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>demolizione del veicolo in Italia;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>consulenza medica;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio di autoambulanza in Italia;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>richiesta di documenti in caso di furto totale del veicolo;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>auto in sostituzione durante la riparazione (valida anche nel comune di residenza dell’Assicurato);</div></li>
    </ul>

    <div class='uxa-h-spacer--m'></div>
    <strong>PRESTAZIONI OPERANTI ESCLUSIVAMENTE FUORI DAL COMUNE DI RESIDENZA:</strong>
    <div class='uxa-h-spacer--s'></div>
    <ul class='uxa-m-list'>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio di un taxi per il ritiro dell'auto sostitutiva;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>trasferimento in un centro ospedaliero attrezzato e successivo rientro dopo la degenza;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>spese d'albergo;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>rientro passeggeri dei passeggeri / prosecuzione del viaggio;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio pezzi di ricambio;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>autista a disposizione;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>viaggio per il recupero del veicolo;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>recupero del veicolo a mezzo pianale;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>anticipo spese di prima necessità;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>supporto telefonico alla compilazione del modulo CID/CAI;</div></li>
    </ul>
    <div class='uxa-h-spacer--s'></div>

    <strong>Per ciascuna prestazione sono previsti massimali diversi così come indicato nelle condizioni di assicurazione.</strong>
    `,
    'requires': [],
    'excludes': ['VOLBO'],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 8,
    'code': 'VOLBO',
    'name': 'Black Box & Assistenza Stradale Plus',
    // tslint:disable-next-line:max-line-length
    'description': 'In caso di installazione della Black box OCTO TELEMATICS la garanzia Assistenza Stradale assume la forma Plus che prevede tutte le prestazioni della forma “VOLANTE+”  più le prestazioni della Assistenza Telematica:In caso di ricezione di un allarme automatico crash o mini-crash, la Sala Operativa provvede a verificare lo stato del veicolo e a contattare il Contraente e/o il contatto ai numeri telefonici forniti per procedere con l’erogazione dei servizi di Assistenza necessari.Il dispositivo BlackBox permette di geolocalizzare il veicolo in caso di furto agevolando l’intervento delle autorità.Infine, in caso di recupero con carro attrezzi del veicolo, è prevista la possibilità di consegna dell’auto sostitutiva direttamente nel luogo di fermo del veicolo.È disponibile l’App howUdrive, sia per device iOS che Android, che permette di verificare gli eventuali urti/collisioni rilevati dalla BlackBox, i km percorsi suddivisi per fasce orarie e tipologia di strada, i numeri utili per l’assistenza e/o il soccorso stradale.',
    'requires': [],
    'excludes': ['VOLAS'],
    'clauses': {},
    'optional': true,
    'showReadMore': true
  }
];

export const guaranteeListMotorBike: Array<Guarantee> = [
  {
    'weight': 1,
    'code': '#AFFINITY-AUTO-RCA',
    'name': 'Responsabilità Civile Auto',
    // tslint:disable-next-line:max-line-length
    'description': "Sono assicurati tutti i danni procurati a terzi o cose di terzi durante <b>la circolazione stradale</b> (anche in aree private, escluse le zone aereoportuali) qualsiasi sia il conducente del veicolo (anche un neopatentato) ed il kilometraggio (illimitato). Sono inoltre risarcibili i danni causati:\n<ul><li>da passeggeri a terze persone (ad es. un passeggero che apre la portiera e provoca danni a terzi);</li><li>con il limite di € 250.000 a mobili e immobili di terzi dall'incendio del veicolo anche quando non è in circolazione (ad es. il veicolo incendia nel garage e le fiamme danneggiano altri veicoli vicini);</li><li>da conducenti con patente idonea ma scaduta o causati da figli minori (salvo ciclomotori);</li><li>in caso di omesso o errato utilizzo delle cinture di sicurezza e/o dei sistemi di ritenuta dei bambini la garanzia RCA è sempre valida;</li></ul><br><b>In caso di guida eccezzionalmente in stato di ebbrezza la rivalza viene limitata ad € 500.</b><br><b>La garanzia contempla anche l'estensione Danni patrimoniali puri, con un massimale annuo di € 20.000</b>",
    'requires': [],
    'excludes': [],
    'clauses': {
      'AFFINITY-AUTO-RCA-TIPO-GUIDA': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'Tipo Guida',
          'description': 'Libera',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'Tipo Guida',
          'description': 'Esperta',
          'value': '1',
          'factor': 1
        },
        '3': {
          'type': 'LIMITAZIONE',
          'code': '3',
          'name': 'Tipo Guida',
          'description': 'Esclusiva',
          'value': '3',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-GANCIO-TRAINO': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'Gancio Traino',
          'description': 'No',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'Gancio Traino',
          'description': 'Sì',
          'value': '1',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-RINUNCIA-RIVALSA-EBREZZA': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'RIvalsa In Caso di Ebrezza',
          'description': 'No',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'RIvalsa In Caso di Ebrezza',
          'description': 'Sì',
          'value': '1',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-MASSIMALE': {
        '2019120101': {
          'type': 'MASSIMALE',
          'code': '2019120101',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': 'MINIMO DI LEGGE',
          'factor': 1,
          'origDescription': '01,22/ 06,07/ 07,29'
        },
        '2019120102': {
          'type': 'MASSIMALE',
          'code': '2019120102',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 8.500.000 UNICO',
          'factor': 1,
          'origDescription': '08,50/ 08,50/ 08,50'
        },
        '2019120103': {
          'type': 'MASSIMALE',
          'code': '2019120103',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 10.000.000 UNICO',
          'factor': 1,
          'origDescription': '10,00/ 10,00/ 10,00'
        },
        '2019120104': {
          'type': 'MASSIMALE',
          'code': '2019120104',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 15.000.000 UNICO',
          'factor': 1,
          'origDescription': '15,00/ 15,00/ 15,00'
        },
        '2019120105': {
          'type': 'MASSIMALE',
          'code': '2019120105',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 25.000.000 UNICO',
          'factor': 1,
          'origDescription': '25,00/ 25,00/ 25,00'
        },
        '2019120106': {
          'type': 'MASSIMALE',
          'code': '2019120106',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 50.000.000 UNICO',
          'factor': 1,
          'origDescription': '50,00/ 50,00/ 50,00'
        }
      },
      'AFFINITY-AUTO-RCA-SOSPENDIBILITA': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'Sospendiblità',
          'description': 'No',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'Sospendiblità',
          'description': 'Sì',
          'value': '1',
          'factor': 1
        }
      }
    },
    'optional': false,
    'dataInput': [
      {
        'type': 'NUMBER',
        'variableName': 'VALORE_VIAGGIO',
        'label': 'Valore del viaggio in €uro',
        'defaultValue': '100',
        'check': {
          'min': 1,
          'max': 10000
        }
      },
      {
        'type': 'DATE',
        'variableName': 'DATA_ACQUISTO',
        'label': 'Data di acquisto',
        'defaultValue': '26.03.2020',
        'check': {
          'min': '25.03.2020',
          'max': '26.03.2020'
        }
      }
    ],
    'showReadMore': true
  },
  {
    'weight': 2,
    'code': 'FURIN',
    'name': 'Furto e Incendio',
    'description': `Prevede un indennizzo per i danni subiti dal veicolo in seguito a sviluppo di fiamma, esplosione, scoppio e azione del fulmine, furto (consumato o tentato) e rapina.<br>La garanzia "Incendio e Furto" può essere personalizzata con una o più delle seguenti condizioni complementari:`,
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 5,
    'code': 'LEGAL',
    'name': 'Tutela Legale',
    'description': 'Prevede il pagamento, in sede giudiziale e stragiudiziale, delle spese legali che potrebbero essere necessarie per la tutela degli interessi dell’Assicurato per fatti inerenti la circolazione. Le prestazioni vengono erogate da D.A.S. Difesa Automobilistica Sinistri S.p.A.',
    // tslint:disable-next-line:max-line-length
    'infoBox': "Valida solo per autovetture - motocicli - ciclomotori - veicoli diversi: <strong>entro il massimale di euro 30.000,00</strong> garantisce un servizio di assistenza legale (sia civile che penale) in caso di controversie derivanti da sinistro stradale e più precisamente:<div class='uxa-h-spacer--m'></div><ul class='uxa-m-list'><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di assistenza stragiudiziale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>gli oneri per l’intervento di un legale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>gli oneri per l’intervento di un perito;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di giustizia, in caso di condanna legale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese liquidate dalla controparte in caso di soccombenza;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese eventualmente dovute alla controparte nel caso di transazione autorizzata dalla Direzione Generale DAS;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di indagini per la ricerca di prove a difesa;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese per la remissione di denunce, querele, istanze alla Autorità Giudiziaria.</div></li></ul>",
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 6,
    'code': 'INFCO',
    // tslint:disable-next-line:max-line-length
    'infoBox': "Valida solo per autovetture, motocicli, ciclomotori e veicoli diversi: <strong>entro il massimale di euro 80.000,00</strong> garantisce un indennizzo al conducente del veicolo assicurato nel caso di morte o invalidità permanente a seguito di un incidente stradale; sono in garanzia anche gli infortuni (alla guida del veicolo assicurato) derivanti da: <div class='uxa-h-spacer--m'></div><ul class='uxa-m-list'><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>asfissia non di origine morbosa, le lesioni riportate in conseguenza della inspirazione di gas e di vapori; </div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>colpi di sole o di calore e annegamento;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>assideramento o congelamento e folgorazione;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>stato di malore o incoscienza o infarto miocardico o cerebrale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>imperizia, negligenza o imprudenza  anche grave;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>caduta di rocce, pietre, alberi e simili, nonché da valanghe o slavine.</div></li></ul><div class='uxa-h-spacer--xxl'></div><strong>La liquidazione dei danni, in caso di invalidità permanente, è gravata da franchigia pari a 3 punti percentuali</strong>",
    'name': 'Infortuni del conducente',
    'description': 'Prevede un indennizzo per gli infortuni che può subire il conducente quando si trova alla guida del veicolo assicurato oppure quando scende o sale dal veicolo, carica o scarica bagagli o effettua controlli o riparazioni di guasti.',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 7,
    'code': 'VOLAS',
    'name': 'Assistenza Stradale',
    'description': 'Garantisce numerosi servizi al veicolo ed alla persona in caso di emergenza conseguente ad incidente stradale, incendio, guasto meccanico, furto o rapina. Le prestazioni vengono erogate da Europ Assistance Italia S.p.A.',
    'infoBox': `Valida solo per autovetture - motocicli - autocarri/camper (con un peso complessivo a pieno carico pari o inferiore a 3,5 t).<br>
    Garantisce numerosi servizi al veicolo ed alla persona in caso di emergenza conseguente ad incendio, incidente stradale, guasto, furto, foratura pneumatico, smarrimento chiavi o mancanza carburante. Le prestazioni erogate da Europ Assistance vengono divise in tre macro classi: senza franchigia chilometrica, fuori dal comune di residenza e all'estero. <br><br>

    <div class='uxa-h-spacer--s'></div>
    <strong>SENZA FRANCHIGIA CHILOMETRICA:</strong>
    <div class='uxa-h-spacer--s'></div>
    <ul class='uxa-m-list'>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio di un carro attrezzi e/o officina mobile;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>recupero del veicolo fuori strada;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>demolizione del veicolo in Italia;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>consulenza medica;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio di autoambulanza in Italia;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>richiesta di documenti in caso di furto totale del veicolo;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>auto in sostituzione durante la riparazione (valida anche nel comune di residenza dell’Assicurato);</div></li>
    </ul>

    <div class='uxa-h-spacer--m'></div>
    <strong>PRESTAZIONI OPERANTI ESCLUSIVAMENTE FUORI DAL COMUNE DI RESIDENZA:</strong>
    <div class='uxa-h-spacer--s'></div>
    <ul class='uxa-m-list'>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio di un taxi per il ritiro dell'auto sostitutiva;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>trasferimento in un centro ospedaliero attrezzato e successivo rientro dopo la degenza;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>spese d'albergo;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>rientro passeggeri dei passeggeri / prosecuzione del viaggio;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>invio pezzi di ricambio;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>autista a disposizione;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>viaggio per il recupero del veicolo;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>recupero del veicolo a mezzo pianale;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>anticipo spese di prima necessità;</div></li>
    <li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>supporto telefonico alla compilazione del modulo CID/CAI;</div></li>
    </ul>
    <div class='uxa-h-spacer--s'></div>

    <strong>Per ciascuna prestazione sono previsti massimali diversi così come indicato nelle condizioni di assicurazione.</strong>
    `,
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  }
];

export const guaranteeListBike: Array<Guarantee> = [
  {
    'weight': 1,
    'code': '#AFFINITY-AUTO-RCA',
    'name': 'Responsabilità Civile Auto',
    // tslint:disable-next-line:max-line-length
    'description': "Sono assicurati tutti i danni procurati a terzi o cose di terzi durante <b>la circolazione stradale</b> (anche in aree private, escluse le zone aereoportuali) qualsiasi sia il conducente del veicolo (anche un neopatentato) ed il kilometraggio (illimitato). Sono inoltre risarcibili i danni causati:\n<ul><li>da passeggeri a terze persone (ad es. un passeggero che apre la portiera e provoca danni a terzi);</li><li>con il limite di € 250.000 a mobili e immobili di terzi dall'incendio del veicolo anche quando non è in circolazione (ad es. il veicolo incendia nel garage e le fiamme danneggiano altri veicoli vicini);</li><li>da conducenti con patente idonea ma scaduta o causati da figli minori (salvo ciclomotori);</li><li>in caso di omesso o errato utilizzo delle cinture di sicurezza e/o dei sistemi di ritenuta dei bambini la garanzia RCA è sempre valida;</li></ul><br><b>In caso di guida eccezzionalmente in stato di ebbrezza la rivalza viene limitata ad € 500.</b><br><b>La garanzia contempla anche l'estensione Danni patrimoniali puri, con un massimale annuo di € 20.000</b>",
    'requires': [],
    'excludes': [],
    'clauses': {
      'AFFINITY-AUTO-RCA-TIPO-GUIDA': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'Tipo Guida',
          'description': 'Libera',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'Tipo Guida',
          'description': 'Esperta',
          'value': '1',
          'factor': 1
        },
        '3': {
          'type': 'LIMITAZIONE',
          'code': '3',
          'name': 'Tipo Guida',
          'description': 'Esclusiva',
          'value': '3',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-GANCIO-TRAINO': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'Gancio Traino',
          'description': 'No',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'Gancio Traino',
          'description': 'Sì',
          'value': '1',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-RINUNCIA-RIVALSA-EBREZZA': {
        '0': {
          'type': 'LIMITAZIONE',
          'code': '0',
          'name': 'RIvalsa In Caso di Ebrezza',
          'description': 'No',
          'value': '0',
          'factor': 1
        },
        '1': {
          'type': 'LIMITAZIONE',
          'code': '1',
          'name': 'RIvalsa In Caso di Ebrezza',
          'description': 'Sì',
          'value': '1',
          'factor': 1
        }
      },
      'AFFINITY-AUTO-RCA-MASSIMALE': {
        '2019120101': {
          'type': 'MASSIMALE',
          'code': '2019120101',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': 'MINIMO DI LEGGE',
          'factor': 1,
          'origDescription': '01,22/ 06,07/ 07,29'
        },
        '2019120102': {
          'type': 'MASSIMALE',
          'code': '2019120102',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 8.500.000 UNICO',
          'factor': 1,
          'origDescription': '08,50/ 08,50/ 08,50'
        },
        '2019120103': {
          'type': 'MASSIMALE',
          'code': '2019120103',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 10.000.000 UNICO',
          'factor': 1,
          'origDescription': '10,00/ 10,00/ 10,00'
        },
        '2019120104': {
          'type': 'MASSIMALE',
          'code': '2019120104',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 15.000.000 UNICO',
          'factor': 1,
          'origDescription': '15,00/ 15,00/ 15,00'
        },
        '2019120105': {
          'type': 'MASSIMALE',
          'code': '2019120105',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 25.000.000 UNICO',
          'factor': 1,
          'origDescription': '25,00/ 25,00/ 25,00'
        },
        '2019120106': {
          'type': 'MASSIMALE',
          'code': '2019120106',
          'description': 'in milioni di euro per <b>danni a cose / persone / sinistro</b>',
          'name': '',
          'value': '€ 50.000.000 UNICO',
          'factor': 1,
          'origDescription': '50,00/ 50,00/ 50,00'
        }
      }
    },
    'optional': false,
    'dataInput': [
      {
        'type': 'NUMBER',
        'variableName': 'VALORE_VIAGGIO',
        'label': 'Valore del viaggio in €uro',
        'defaultValue': '100',
        'check': {
          'min': 1,
          'max': 10000
        }
      },
      {
        'type': 'DATE',
        'variableName': 'DATA_ACQUISTO',
        'label': 'Data di acquisto',
        'defaultValue': '26.03.2020',
        'check': {
          'min': '25.03.2020',
          'max': '26.03.2020'
        }
      }
    ],
    'showReadMore': true
  },
  {
    'weight': 2,
    'code': 'FURIN',
    'name': 'Furto e Incendio',
    'description': `Prevede un indennizzo per i danni subiti dal veicolo in seguito a sviluppo di fiamma, esplosione, scoppio e azione del fulmine, furto (consumato o tentato) e rapina.<br>La garanzia "Incendio e Furto" può essere personalizzata con una o più delle seguenti condizioni complementari:`,
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 5,
    'code': 'LEGAL',
    'name': 'Tutela Legale',
    'description': 'Prevede il pagamento, in sede giudiziale e stragiudiziale, delle spese legali che potrebbero essere necessarie per la tutela degli interessi dell’Assicurato per fatti inerenti la circolazione. Le prestazioni vengono erogate da D.A.S. Difesa Automobilistica Sinistri S.p.A.',
    // tslint:disable-next-line:max-line-length
    'infoBox': "Valida solo per autovetture - motocicli - ciclomotori - veicoli diversi: <strong>entro il massimale di euro 30.000,00</strong> garantisce un servizio di assistenza legale (sia civile che penale) in caso di controversie derivanti da sinistro stradale e più precisamente:<div class='uxa-h-spacer--m'></div><ul class='uxa-m-list'><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di assistenza stragiudiziale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>gli oneri per l’intervento di un legale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>gli oneri per l’intervento di un perito;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di giustizia, in caso di condanna legale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese liquidate dalla controparte in caso di soccombenza;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese eventualmente dovute alla controparte nel caso di transazione autorizzata dalla Direzione Generale DAS;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese di indagini per la ricerca di prove a difesa;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>le spese per la remissione di denunce, querele, istanze alla Autorità Giudiziaria.</div></li></ul>",
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  },
  {
    'weight': 6,
    'code': 'INFCO',
    // tslint:disable-next-line:max-line-length
    'infoBox': "Valida solo per autovetture, motocicli, ciclomotori e veicoli diversi: <strong>entro il massimale di euro 80.000,00</strong> garantisce un indennizzo al conducente del veicolo assicurato nel caso di morte o invalidità permanente a seguito di un incidente stradale; sono in garanzia anche gli infortuni (alla guida del veicolo assicurato) derivanti da: <div class='uxa-h-spacer--m'></div><ul class='uxa-m-list'><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>asfissia non di origine morbosa, le lesioni riportate in conseguenza della inspirazione di gas e di vapori; </div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>colpi di sole o di calore e annegamento;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>assideramento o congelamento e folgorazione;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>stato di malore o incoscienza o infarto miocardico o cerebrale;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>imperizia, negligenza o imprudenza  anche grave;</div></li><li class='uxa-m-list__item uxa-state-m-list__item--xs'><div class='uxa-h-typography--normal-xs uxa-m-list__item--checkmark-secondary'>caduta di rocce, pietre, alberi e simili, nonché da valanghe o slavine.</div></li></ul><div class='uxa-h-spacer--xxl'></div><strong>La liquidazione dei danni, in caso di invalidità permanente, è gravata da franchigia pari a 3 punti percentuali</strong>",
    'name': 'Infortuni del conducente',
    'description': 'Prevede un indennizzo per gli infortuni che può subire il conducente quando si trova alla guida del veicolo assicurato oppure quando scende o sale dal veicolo, carica o scarica bagagli o effettua controlli o riparazioni di guasti.',
    'requires': [],
    'excludes': [],
    'clauses': {},
    'optional': true,
    'showReadMore': false
  }

];
