import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgSellyConfig } from 'ng-selly.config';
import { InsuranceProduct, Policy, PolicyStatus, Sex, SurveyQuestionResult, SurveyQuestionType } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/models';
import * as sortBy from 'lodash/sortBy';
import { SpinnerController } from '@helvetia-italia/ng-selly-components/src/lib/spinner-controller/spinner.controller';

const configClass: any = new NgSellyConfig();

@Injectable()
export class PolicyListService {
  _list: Array<Policy> = [];

  constructor(private http: HttpClient, private config: NgSellyConfig, protected spinnerController: SpinnerController) {
  }

  public getList(name: string, surname: string): Promise<Array<Policy>> {
    console.log('[PolicyListService][getList]');
    this.spinnerController.show('Ricerca Polizze');
    console.log(this.config.apiServer);
    return new Promise<Array<Policy>>((resolve, reject) => {
      this.http.get<Array<Policy>>(`${this.config.apiServer}api/${name}/${surname}/policySearch`, {
        withCredentials: true,
      }).subscribe((productList: Array<Policy>) => {
        productList = sortBy(productList, [(product: InsuranceProduct) => product.weight]);
        this._list = productList;
        this.spinnerController.close();
        resolve(this._list);
      });
    });
  }
}


@Injectable()
export class PolicyListMockService extends PolicyListService {

  public getList(): Promise<Array<Policy>> {
    console.log('[PolicyListMockService][getList]');
    this.spinnerController.show('Ricerca Polizze');

    if (this._list.length > 0)
      return Promise.resolve(this._list);

    // @ts-ignore
    this._list = [
      {
        'productCode': 'EASYTRAVEL',
        'productNode': '00400',
        'variables': {
          'ASSICURATI': 1,
          'DECORRENZA': '2019-06-27',
          'FINE_COPERTURA': '2019-07-25',
          'METE': [
            'Antigua e Barbuda'
          ],
          'DESTINAZIONE': 'USA/CANADA',
          'DURATA': 30
        },
        'insured': [{
          'type': 'PhysicalPeople',
          'idx': 100,
          'firstName': 'Raffaele',
          'surname': 'Armini',
          'fiscalCode': 'RMNRFL85D04A783X',
          'sex': Sex.MALE,
          'birth': {
            'date': '1985-04-04',
            'district': 'BN',
            'nation': 'IT',
            'place': 'Benevento'
          },
          'italianResidential': true
        }
        ],
        'survey': {
          'code': 'OKTRAVEL-SURVEY',
          'title': 'Questionario per la valutazione della coerenza del contratto \'OK Travel Helvetia\'',
          // tslint:disable-next-line:max-line-length
          'text': 'Gentile Cliente, \n le risposte alle domande che seguono consentiranno al distributore di valutare la coerenza del contratto rispetto alle Sue esigenze assicurative. \n Può anche scegliere di sottoscrivere il contratto senza rispondere ad alcuna domanda, con la consapevolezza che ciò non consente al distributore la valutazione della coerenza del contratto rispetto alle Sue esigenze assicurative.',
          'questions': {
            'Q1': {
              'code': 'Q1',
              'type': SurveyQuestionType.MULTICHOISE,
              'title': 'Esigenze assicurative',
              'text': 'Quali sono gli obiettivi che Si prefigge con la stipula del contratto?',
              'textNONADEGUATO': 'Gli obiettivi di copertura assicurativa non risultano coerenti perché la risposta alla domanda 1:',
              'formula': {
                // tslint:disable-next-line:max-line-length
                'ADEGUATO': 'Q1R1 && (OKTRAVEL-RCT ^^ !Q1R2) && (OKTRAVEL-ANNULLAMENTO ^^ !Q1R3) && (OKTRAVEL-TUTELA ^^ !Q1R4) && ((OKTRAVEL-9000-INFORTUNI || OKTRAVEL-4AGENTS-INFORTUNI) ^^ !Q1R5) && !Q1R6',
                // tslint:disable-next-line:max-line-length
                'NONADEGUATO': '!Q1R1 || (OKTRAVEL-RCT ^^ Q1R2) || (OKTRAVEL-ANNULLAMENTO ^^ Q1R3) || (OKTRAVEL-TUTELA ^^ Q1R4) || ((OKTRAVEL-9000-INFORTUNI || OKTRAVEL-4AGENTS-INFORTUNI) ^^ Q1R5) || Q1R6',
                'NONVALUTABILE': 'Q1R7'
              },
              'answers': {
                'Q1R1': {
                  'code': 'Q1R1',
                  // tslint:disable-next-line:max-line-length
                  'text': 'Voglio proteggere il mio patrimonio da eventuali necessità di assistenza immediata durante il viaggio, voglio un rimborso per spese necessarie per eventuali infortuni e/o malattie',
                  'textNONADEGUATO': '1.1 - non sono stati compresi degli aspetti fondamentali del contratto',
                  'value': false
                },
                'Q1R2': {
                  'code': 'Q1R2',
                  'text': 'Voglio proteggere il mio patrimonio da richieste di risarcimento per i danni che posso provocare a terzi',
                  'textNONADEGUATO': '1.2 - è in contrasto con la scelta effettuata per la garanzia relativa alla responsabilità civile per danni a terzi.',
                  'value': false
                },
                'Q1R3': {
                  'code': 'Q1R3',
                  'text': 'Voglio avere una tutela in caso di annullamento del viaggio per cause imprevedibili',
                  'textNONADEGUATO': '1.3 - è in contrasto con la scelta effettuata per la garanzia relativa all\'annullamento viaggio.',
                  'value': false,
                },
                'Q1R4': {
                  'code': 'Q1R4',
                  'text': 'Voglio proteggere il mio patrimonio da spese legali in caso di controversie con terzi',
                  'textNONADEGUATO': '1.4 - è in contrasto con la scelta effettuata per la garanzia relativa alla tutela legale.',
                  'value': false
                },
                'Q1R5': {
                  'code': 'Q1R5',
                  'text': 'Voglio esclusivamente proteggere la mia persona in caso di infortunio durante il viaggio',
                  'textNONADEGUATO': '1.5 - è in contrasto con la scelta effettuata per la garanzia relativa agli infortuni.',
                  'value': false
                },
                'Q1R6': {
                  'code': 'Q1R6',
                  'text': 'Voglio protezione per altri eventi come per esempio \'inabilità temporanea da infortunio e/o da malattia\'',
                  'textNONADEGUATO': '1.6 - la garanzia non è prevista dal presente prodotto',
                  'value': false
                }
                ,
                'Q1R7':
                  {
                    'code': 'Q1R7',
                    'text': 'Non rispondo',
                    'value': true
                  }
              },
              'result': SurveyQuestionResult.NONVALUTABILE
            },
            'Q2':
              {
                'code': 'Q2',
                'type': SurveyQuestionType.SINGLECHOISE,
                'title': 'Altre Assicurazioni',
                'text':
                  'Ha in corso altre coperture assicurative che coprono i medesimi rischi della presente polizza?',
                'textNONADEGUATO': 'Gli obiettivi di copertura assicurativa non risultano coerenti perché la risposta alla domanda 2:',
                'formula': {
                  'ADEGUATO': 'Q2R1 || Q2R3',
                  'NONADEGUATO': 'Q2R2',
                  'NONVALUTABILE': 'Q2R4'
                },
                'answers': {
                  'Q2R1': {
                    'code': 'Q2R1',
                    'text': 'Sì, ma intendo acquistare una copertura complementare',
                    'value': false
                  }
                  ,
                  'Q2R2': {
                    'code': 'Q2R2',
                    'text': 'Sì, e non intendo integrare la copertura in corso',
                    'textNONADEGUATO': '2 - E\'già esistente un\'altra copertura assicurativa per i medesimi rischi',
                    'value': false
                  }
                  ,
                  'Q2R3':
                    {
                      'code': 'Q2R3',
                      'text': 'No',
                      'value': false
                    }
                  ,
                  'Q2R4':
                    {
                      'code': 'Q2R4',
                      'text': 'Non rispondo',
                      'value': true
                    }
                },
                'result': SurveyQuestionResult.NONVALUTABILE
              },
            'Q3': {
              'code': 'Q3',
              'type': SurveyQuestionType.SINGLECHOISE,
              'title':
                'Delimitazioni di garanzia e limitazioni d’indennizzo',
              // tslint:disable-next-line:max-line-length
              'text': 'È consapevole che le garanzie prestate dal prodotto OK Travel Helvetia operano esclusivamente durante il periodo in cui è in viaggio e prevede limiti alle coperture fornite ed alle prestazioni erogabili?',
              'textNONADEGUATO': 'Gli obiettivi di copertura assicurativa non risultano coerenti perché la risposta alla domanda 3:',
              'formula':
                {
                  'ADEGUATO':
                    'Q3R1',
                  'NONADEGUATO':
                    'Q3R2',
                  'NONVALUTABILE':
                    'Q3R3'
                }
              ,
              'answers':
                {
                  'Q3R1':
                    {
                      'code':
                        'Q3R1',
                      'text':
                        'Sì',
                      'value':
                        false
                    }
                  ,
                  'Q3R2':
                    {
                      'code':
                        'Q3R2',
                      'text':
                        'No',
                      'textNONADEGUATO': '3 - Mancata comprensione degli aspetti fondamentali del contratto',
                      'value': false
                    }
                  ,
                  'Q3R3':
                    {
                      'code': 'Q3R3',
                      'text': 'Non rispondo',
                      'value': true
                    }
                }
              ,
              'result': SurveyQuestionResult.NONVALUTABILE
            }
          }
        },
        // 'packageName': 'Basic',
        'packageCode': 'OKTRAVEL-EASY-9000',
        'guarantees': {
          'OKTRAVEL-ASSISTENZA-SANITARIA':
            {
              'code':
                'OKTRAVEL-ASSISTENZA-SANITARIA',
              'description':
                'Assistenza 24 ore su 24 per qualsiasi urgenza.',
              'name':
                'Assistenza sanitaria \'All Risks\' & Lifestyle ',
              'totalAmount':
                20,
              'totalTax':
                1.82,
              'totalNet':
                18.18,
              'price':
                20,
              'taxes':
                1.82,
              'net':
                18.18,
              'taxPerc':
                0.1,
              'clauses': {
                'OKTRAVEL-ASSISTENZA-SANITARIA-MASSIMALE': '*'
              },
              'formulas': {
                '': ''
              }
            },
          'OKTRAVEL-9000-RIMBORSO-DIRETTO': {
            'code':
              'OKTRAVEL-9000-RIMBORSO-DIRETTO',
            'description':
              'Pagamento diretto delle spese mediche ospedaliere e chirurgiche non procrastinabili.',
            'name':
              'Spese mediche in viaggio',
            'totalAmount':
              68.5,
            'totalTax':
              1.67,
            'totalNet':
              66.83,
            'price':
              68.5,
            'taxes':
              1.67,
            'net':
              66.83,
            'taxPerc':
              0.025,
            'clauses': {
              'OKTRAVEL-9000-RIMBORSO-DIRETTO-MASSIMALE': '*'
            },
            'formulas': {
              '': ''
            }
          }
        },
        // 'remoteSelling': '00400',
        'policyHolder': {
          'type': 'PhysicalPeople',
          'idx': 100,
          'firstName': 'Raffaele',
          'surname': 'Armini',
          'fiscalCode': 'RMNRFL85D04A783X',
          'sex': Sex.MALE,
          'email':
            'raffaelearmini@gmail.com',
          'residentialAddress':
            {
              'address':
                'Via Dell\'Irpinia',
              'addressNumber':
                '73',
              'city':
                'Fisciano',
              'zipCode':
                '84084',
              'district':
                'SA',
              'nation':
                'IT'
            }
          ,
          'italianResidential': true,
          'checkPrivacy': true,
          'checkMarketingPrivacy': false,
          'checkProfilePrivacy': false,
          'flyerRead': true,
          'phone': '+393285571602',
          'birth': {
            'date':
              '1985-04-04',
            'district':
              'BN',
            'nation':
              'IT',
            'place':
              'Benevento'
          },
          'privacyRead': true
        }
        ,
        'touchPoint': 'tornacasa',
        'quotationNumber': '62feaed7-06db-4a8f-86d2-d3982e839851',
        'transactionId': '8032112928SL14uqenkg7rcv3',
        'paymentDate': '2019-06-20T14:08:52',
        'signPoints': [
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Dichiarazioni del contraente - Firma Contraente',
            'page': 1,
            'top': 311,
            'left': 540,
            'width': 300,
            'height': 30,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Norme da approvarsi specificatamente - Firma Contraente',
            'page': 1,
            'top': 852,
            'left': 540,
            'width': 300,
            'height': 25,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Esito Valutazione Questionario - Firma Contraente',
            'page': 2,
            'top': 1047,
            'left': 443,
            'width': 144,
            'height': 25,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Privacy/1 - Firma Contraente',
            'page': 4,
            'top': 474,
            'left': 590,
            'width': 280,
            'height': 35,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Privacy/2 - Firma Contraente',
            'page': 4,
            'top': 905,
            'left': 590,
            'width': 280,
            'height': 35,
            'isOptional': false
          }
        ],
        'status': PolicyStatus.ISSUED,
        'proposalNumber': '73000873',
        'policyNumber': '004002573000873',
        'duration': 29,
        'totalAmount': 88.5,
        'totalAmountTax': 3.49,
        'dates': {
          'issue': {
            'date':
              '2019-06-20',
            'hour':
              '14:08'
          },
          'startCoverage': {
            'date': '2019-06-26',
            'hour': '24:00'
          },
          'expiry': {
            'date': '2019-07-25',
            'hour': '24:00'
          }
        },
        'factors': {
          'EDICGA': {
            'code': 'EDICGA',
            'value': 28
          },
          'CODMOD': {
            'code': 'CODMOD',
            'value': 84
          },
          '1RAMO': {
            'code': '1RAMO',
            'value': 25
          },
          'COSTVI': {
            'code': 'COSTVI',
            'value': 88.5
          },
          'CORISC': {
            'code': 'CORISC',
            'value': 491
          },
          'DESTVI': {
            'code':
              'DESTVI',
            'value':
              4
          }
        },
        'datesFormatted': {
          'issue':
            {
              'date':
                '20/06/2019',
              'hour':
                '14:08'
            }
          ,
          'startCoverage':
            {
              'date':
                '26/06/2019',
              'hour':
                '24:00'
            }
          ,
          'expiry':
            {
              'date':
                '25/07/2019',
              'hour':
                '24:00'
            }
        },
        // 'productName': 'Helvetia Ok Travel',
        // 'totalNet': 85.01,
        'printTemplate': 'EASYTRAVEL',
        'appendages': [],
        'asShop': ''
      },
      {
        'productCode': 'EASYTRAVEL',
        'visitorId': '39635261079796913531653835055292165431',
        'productNode': '09000',
        'variables': {
          'ASSICURATI': 1,
          'DECORRENZA':
            '2019-06-26',
          'FINE_COPERTURA':
            '2019-07-07',
          'METE': [
            'Brasile'
          ],
          'DESTINAZIONE':
            'MONDO',
          'DURATA':
            16,
          'HELVETIA_COUPONCODE':
            'AB126'
        },
        'insured': [
          {
            'type': 'PhysicalPeople',
            'idx': 100,
            'firstName': 'Raffaele',
            'surname': 'Armini',
            'fiscalCode': 'RMNRFL85D04A783X',
            'sex': Sex.MALE,
            'birth': {
              'date': '1985-04-04',
              'district': 'BN',
              'nation': 'IT',
              'place': 'Benevento'
            },
            'italianResidential': true
          }
        ],
        'survey': {
          'code':
            'OKTRAVEL-SURVEY',
          'title':
            'Questionario per la valutazione della coerenza del contratto \'OK Travel Helvetia\'',
          'text':
          // tslint:disable-next-line:max-line-length
            'Gentile Cliente, \n le risposte alle domande che seguono consentiranno al distributore di valutare la coerenza del contratto rispetto alle Sue esigenze assicurative. \n Può anche scegliere di sottoscrivere il contratto senza rispondere ad alcuna domanda, con la consapevolezza che ciò non consente al distributore la valutazione della coerenza del contratto rispetto alle Sue esigenze assicurative.',
          'questions':
            {
              'Q1':
                {
                  'code':
                    'Q1',
                  'type': SurveyQuestionType.MULTICHOISE,
                  'title':
                    'Esigenze assicurative',
                  'text':
                    'Quali sono gli obiettivi che Si prefigge con la stipula del contratto?',
                  'textNONADEGUATO':
                    'Gli obiettivi di copertura assicurativa non risultano coerenti perché la risposta alla domanda 1:',
                  'formula':
                    {
                      'ADEGUATO':
                      // tslint:disable-next-line:max-line-length
                        'Q1R1 && (OKTRAVEL-RCT ^^ !Q1R2) && (OKTRAVEL-ANNULLAMENTO ^^ !Q1R3) && (OKTRAVEL-TUTELA ^^ !Q1R4) && ((OKTRAVEL-9000-INFORTUNI || OKTRAVEL-4AGENTS-INFORTUNI) ^^ !Q1R5) && !Q1R6',
                      'NONADEGUATO':
                      // tslint:disable-next-line:max-line-length
                        '!Q1R1 || (OKTRAVEL-RCT ^^ Q1R2) || (OKTRAVEL-ANNULLAMENTO ^^ Q1R3) || (OKTRAVEL-TUTELA ^^ Q1R4) || ((OKTRAVEL-9000-INFORTUNI || OKTRAVEL-4AGENTS-INFORTUNI) ^^ Q1R5) || Q1R6',
                      'NONVALUTABILE':
                        'Q1R7'
                    }
                  ,
                  'answers':
                    {
                      'Q1R1':
                        {
                          'code':
                            'Q1R1',
                          'text':
                          // tslint:disable-next-line:max-line-length
                            'Voglio proteggere il mio patrimonio da eventuali necessità di assistenza immediata durante il viaggio, voglio un rimborso per spese necessarie per eventuali infortuni e/o malattie',
                          'textNONADEGUATO':
                            '1.1 - non sono stati compresi degli aspetti fondamentali del contratto',
                          'value':
                            false
                        }
                      ,
                      'Q1R2':
                        {
                          'code':
                            'Q1R2',
                          'text':
                            'Voglio proteggere il mio patrimonio da richieste di risarcimento per i danni che posso provocare a terzi',
                          'textNONADEGUATO':
                            '1.2 - è in contrasto con la scelta effettuata per la garanzia relativa alla responsabilità civile per danni a terzi.',
                          'value': false
                        }
                      ,
                      'Q1R3':
                        {
                          'code':
                            'Q1R3',
                          'text':
                            'Voglio avere una tutela in caso di annullamento del viaggio per cause imprevedibili',
                          'textNONADEGUATO':
                            '1.3 - è in contrasto con la scelta effettuata per la garanzia relativa all\'annullamento viaggio.',
                          'value':
                            false
                        }
                      ,
                      'Q1R4':
                        {
                          'code':
                            'Q1R4',
                          'text':
                            'Voglio proteggere il mio patrimonio da spese legali in caso di controversie con terzi',
                          'textNONADEGUATO':
                            '1.4 - è in contrasto con la scelta effettuata per la garanzia relativa alla tutela legale.',
                          'value':
                            false
                        }
                      ,
                      'Q1R5':
                        {
                          'code':
                            'Q1R5',
                          'text':
                            'Voglio esclusivamente proteggere la mia persona in caso di infortunio durante il viaggio',
                          'textNONADEGUATO':
                            '1.5 - è in contrasto con la scelta effettuata per la garanzia relativa agli infortuni.',
                          'value':
                            false
                        }
                      ,
                      'Q1R6':
                        {
                          'code':
                            'Q1R6',
                          'text':
                            'Voglio protezione per altri eventi come per esempio \'inabilità temporanea da infortunio e/o da malattia\'',
                          'textNONADEGUATO':
                            '1.6 - la garanzia non è prevista dal presente prodotto',
                          'value':
                            false
                        }
                      ,
                      'Q1R7':
                        {
                          'code':
                            'Q1R7',
                          'text':
                            'Non rispondo',
                          'value':
                            true
                        }
                    }
                  ,
                  'result': SurveyQuestionResult.NONVALUTABILE
                }
              ,
              'Q2':
                {
                  'code': 'Q2',
                  'type': SurveyQuestionType.SINGLECHOISE,
                  'title': 'Altre Assicurazioni',
                  'text': 'Ha in corso altre coperture assicurative che coprono i medesimi rischi della presente polizza?',
                  'textNONADEGUATO': 'Gli obiettivi di copertura assicurativa non risultano coerenti perché la risposta alla domanda 2:',
                  'formula':
                    {
                      'ADEGUATO':
                        'Q2R1 || Q2R3',
                      'NONADEGUATO':
                        'Q2R2',
                      'NONVALUTABILE':
                        'Q2R4'
                    }
                  ,
                  'answers':
                    {
                      'Q2R1':
                        {
                          'code':
                            'Q2R1',
                          'text':
                            'Sì, ma intendo acquistare una copertura complementare',
                          'value':
                            false
                        }
                      ,
                      'Q2R2':
                        {
                          'code':
                            'Q2R2',
                          'text':
                            'Sì, e non intendo integrare la copertura in corso',
                          'textNONADEGUATO':
                            '2 - E\' già esistente un\'altra copertura assicu rativa per i medesimi rischi',
                          'value':
                            false
                        }
                      ,
                      'Q2R3':
                        {
                          'code':
                            'Q2R3',
                          'text':
                            'No',
                          'value':
                            false
                        }
                      ,
                      'Q2R4':
                        {
                          'code':
                            'Q2R4',
                          'text':
                            'Non rispondo',
                          'value':
                            true
                        }
                    }
                  ,
                  'result': SurveyQuestionResult.NONVALUTABILE
                }
              ,
              'Q3':
                {
                  'code':
                    'Q3',
                  'type': SurveyQuestionType.SINGLECHOISE,
                  'title': 'Delimitazioni di garanzia e limitazioni d’indennizzo',
                  // tslint:disable-next-line:max-line-length
                  'text': 'È consapevole che le garanzie prestate dal prodotto OK Travel Helvetia operano esclusivamente durante il periodo in cui è in viaggio e prevede limiti alle coperture fornite ed alle prestazioni erogabili?',
                  'textNONADEGUATO': 'Gli obiettivi di copertura assicurativa non risultano coerenti perché la risposta alla domanda 3:',
                  'formula':
                    {
                      'ADEGUATO':
                        'Q3R1',
                      'NONADEGUATO':
                        'Q3R2',
                      'NONVALUTABILE':
                        'Q3R3'
                    }
                  ,
                  'answers':
                    {
                      'Q3R1':
                        {
                          'code':
                            'Q3R1',
                          'text':
                            'Sì',
                          'value':
                            false
                        }
                      ,
                      'Q3R2':
                        {
                          'code':
                            'Q3R2',
                          'text':
                            'No',
                          'textNONADEGUATO':
                            '3 - Mancata comprensione degli aspetti fondamentali del contratto',
                          'value':
                            false
                        }
                      ,
                      'Q3R3':
                        {
                          'code': 'Q3R3',
                          'text': 'Non rispondo',
                          'value': true
                        }
                    }
                  ,
                  'result': SurveyQuestionResult.NONVALUTABILE
                }
            }
          ,
          'result':
            'NONVALUTABILE'
        },
        // 'packageName': 'Basic',
        'packageCode': 'OKTRAVEL-EASY-9000',
        'guarantees': {
          'OKTRAVEL-ASSISTENZA-SANITARIA':
            {
              'code':
                'OKTRAVEL-ASSISTENZA-SANITARIA',
              'description':
                'Assistenza 24 ore su 24 per qualsiasi urgenza.',
              'name':
                'Assistenza sanitaria \'All Risks\' & Lifestyle ',
              'totalAmount':
                12.5,
              'totalTax':
                1.14,
              'totalNet':
                11.36,
              'price':
                12.5,
              'taxes':
                1.14,
              'net':
                11.36,
              'taxPerc':
                0.1,
              'clauses': {
                'OKTRAVEL-ASSISTENZA-SANITARIA-MASSIMALE': '*'
              },
              'formulas': {
                '': ''
              }
            }
          ,
          'OKTRAVEL-9000-RIMBORSO-DIRETTO':
            {
              'code':
                'OKTRAVEL-9000-RIMBORSO-DIRETTO',
              'description':
                'Pagamento diretto delle spese mediche ospedaliere e chirurgiche non procrastinabili.',
              'name':
                'Spese mediche in viaggio',
              'totalAmount':
                43.5,
              'totalTax':
                1.06,
              'totalNet':
                42.44,
              'price':
                43.5,
              'taxes':
                1.06,
              'net':
                42.44,
              'taxPerc':
                0.025,
              'clauses': {
                'OKTRAVEL-9000-RIMBORSO-DIRETTO-MASSIMALE': '*'
              },
              'formulas': {
                '': ''
              }
            }
        },
        'policyHolder': {
          'type': 'PhysicalPeople',
          'idx': 100,
          'firstName':
            'Raffaele',
          'surname':
            'Armini',
          'fiscalCode':
            'RMNRFL85D04A783X',
          'sex': Sex.MALE,
          'email':
            'raffaelearmini@gmail.com',
          'residentialAddress':
            {
              'address':
                'Via Dell\'Irpinia',
              'addressNumber':
                '73',
              'city':
                'Fisciano',
              'zipCode':
                '84084',
              'district':
                'SA',
              'nation':
                'IT'
            }
          ,
          'italianResidential':
            true,
          'checkPrivacy':
            true,
          'checkMarketingPrivacy':
            false,
          'checkProfilePrivacy':
            false,
          'flyerRead':
            true,
          'phone':
            '+393285571602',
          'birth': {
            'date': '1985-04-04',
            'district': 'BN',
            'nation': 'IT',
            'place': 'Benevento'
          }
          ,
          // 'italianResidential': true,
          'privacyRead': true
        },
        'touchPoint': 'tornacasa',
        'quotationNumber': '4c959b38-ed75-4c3f-aeed-f3a70cd51eb5',
        'transactionId': '8032112928SL115cpxqvnswr3',
        'paymentDate': '2019-06-20T14:34:34',
        'signPoints': [
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Dichiarazioni del contraente - Firma Contraente',
            'page': 1,
            'top': 311,
            'left': 540,
            'width': 300,
            'height': 30,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Norme da approvarsi specificatamente - Firma Contraente',
            'page': 1,
            'top': 852,
            'left': 540,
            'width': 300,
            'height': 25,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Esito Valutazione Questionario - Firma Contraente',
            'page': 2,
            'top': 1047,
            'left': 443,
            'width': 144,
            'height': 25,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Privacy/1 - Firma Contraente',
            'page': 4,
            'top': 474,
            'left': 590,
            'width': 280,
            'height': 35,
            'isOptional': false
          },
          {
            'signerId': 'Contraente',
            'shortDescription': 'Firma Contraente',
            'longDescription': 'Privacy/2 - Firma Contraente',
            'page': 4,
            'top': 905,
            'left': 590,
            'width': 280,
            'height': 35,
            'isOptional': false
          }
        ],
        'status': PolicyStatus.ISSUED,
        'proposalNumber': '73000874',
        'policyNumber': '090002573000874',
        'duration': 12,
        'totalAmount': 56,
        'totalAmountTax': 2.2,
        'dates': {
          'issue':
            {
              'date':
                '2019-06-20',
              'hour':
                '14:34'
            }
          ,
          'startCoverage':
            {
              'date':
                '2019-06-25',
              'hour':
                '24:00'
            }
          ,
          'expiry':
            {
              'date':
                '2019-07-07',
              'hour':
                '24:00'
            }
        },
        'factors': {
          'EDICGA':
            {
              'code':
                'EDICGA',
              'value':
                28
            }
          ,
          'CODMOD':
            {
              'code':
                'CODMOD',
              'value':
                80
            }
          ,
          '1RAMO':
            {
              'code':
                '1RAMO',
              'value':
                25
            }
          ,
          'COSTVI': {
            'code': 'COSTVI',
            'value': 56
          },
          'CORISC': {
            'code': 'CORISC',
            'value': 491
          },
          'DESTVI':
            {
              'code': 'DESTVI',
              'value': 3
            }
        },
        'datesFormatted': {
          'issue': {
            'date': '20/06/2019',
            'hour': '14:34'
          },
          'startCoverage': {
            'date': '25/06/2019',
            'hour': '24:00'
          },
          'expiry': {
            'date': '07/07/2019',
            'hour': '24:00'
          }
        },
        // 'productName': 'Helvetia Ok Travel',
        // 'totalNet': 53.8,
        'printTemplate': 'EASYTRAVEL',
        'appendages': [],
        'asShop': ''
      }
    ];

    this.spinnerController.close();
    return Promise.resolve(this._list);
  }
}
