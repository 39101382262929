import { NgModule } from '@angular/core';
import { UxBaseModule } from '@helvetia/ng-ux-base/index';
import { OsCommonModule } from '@helvetia/ng-oscommon/index';
import { NgSellyCustomComponentModule } from '@helvetia-italia/ng-selly-components/src/lib/ng-selly-custom-components';
import { LoaderComponent } from './loader.component';



@NgModule({
  imports: [
    UxBaseModule.forRoot(),
    OsCommonModule.forRoot(),
    NgSellyCustomComponentModule.forRoot(),
  ],
  declarations: [
    LoaderComponent
  ],
  exports: [ LoaderComponent ],
  providers: []
})
export class LoaderModule {
  constructor() {}
}
