import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgSellyConfig } from 'ng-selly.config';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Guarantee, guaranteeListBike, guaranteeListMotorBike, guaranteeListVehicle } from '../remote-service-module/guarantee';
import { SpinnerController } from '@helvetia-italia/ng-selly-components/src/lib/spinner-controller/spinner.controller';

const moment = require('moment');
const configClass: any = new NgSellyConfig();

@Injectable()
export class AffinityQuotesService {

  data;
  currentUserSubject$ = new BehaviorSubject(null);

  constructor(private client: HttpClient, @Inject('env') private env, private spinnerController: SpinnerController) {
  }

  getDataQuote(quote) {
    this.data = this.currentUserSubject$.next(quote);
    this.clearData();
  }

  clearData() {
    this.data = undefined;
    // this.dataDoc = undefined;

  }

  public getQuotes(status?: any, nominativo?: string, fiscalCode?: string) {
    // this.spinnerController.show('Caricamento preventivi');
    let url = `${configClass.config().apiServer}api/quotes`;
    if (!status) {
      status = 'toVerify';
    }
    url = url.concat(`?status=${status}`);
    if (nominativo) {
      url = url.concat(`&nominativo=${nominativo}`);
    }

    if (fiscalCode) {
      url = url.concat(`&cFiscPivaContraente=${fiscalCode}`);
    }
    return this.client.get<any>(url, {observe: 'response', withCredentials: true});
  }

  public getQuotesDetail(quoteNumber) {
    let url = `${configClass.config().apiServer}api/quotes/${quoteNumber}`;
    return this.client.get<any>(url, {observe: 'response', withCredentials: true});
  }

  public updateQuotesDetail(quoteId, quote) {
    let url = `${configClass.config().apiServer}api/quotes/${quoteId}`;
    return this.client.put<any>(url, quote, {withCredentials: true});
  }

  public sendMailPurchase(quoteId, sendMailObj) {
    let url = `${configClass.config().apiServer}api/quotes/${quoteId}/sendMail`;
    return this.client.post<any>(url, sendMailObj, {withCredentials: true});
  }

  public getQuotesDocuments(quoteNumber) {
    let url = `${configClass.config().apiServer}api/quotes/documents/${quoteNumber}`;
    return this.client.get<any>(url, {observe: 'response', withCredentials: true});
  }


  public getFileUrl(ownerId, mediaId, mediaType) {
    let parts = mediaType.split('/');
    let type = parts[0];
    let subType = parts[1];
    let url = `${configClass.config().apiServer}api/media`;

    return this.client.get(url + `/${ownerId}/${mediaId}`, {
      responseType: 'arrayBuffer' as 'json',
      observe: 'response',
      withCredentials: true
    });
  }

  // from mongoDb
  public getDocument(docId) {
    return new Promise<any>((resolve, reject) => {
      let url = `${configClass.config().apiServer}api/documents/${docId}`;
      this.client.get<any>(url, {observe: 'response', withCredentials: true})
        .pipe(take(1)).subscribe((result) => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  public putDocument(docId, payload) {
    console.log('----', docId);
    return new Promise<any>((resolve, reject) => {
      let url = `${configClass.config().apiServer}api/documents/${docId}`;
      this.client.put<any>(url, payload, {
        withCredentials: true,
      }).pipe(take(1)).subscribe((result) => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  guarantees(vehicle): Promise<Array<Guarantee>> {
    // tslint:disable-next-line:switch-default
    switch (vehicle.type) {
      case 'Vehicle':
        return Promise.resolve(guaranteeListVehicle);
      case 'Motorbike':
        return Promise.resolve(guaranteeListMotorBike);
      case 'Bike':
        return Promise.resolve(guaranteeListBike);
    }
  }

}

