import { Component, OnInit } from '@angular/core';
import { FileUploadService } from '@helvetia/ng-oscommon/src/lib/multi-file-upload/index';
import { FileUploadOptions } from '@helvetia/ng-oscommon/src/lib/ng-oscommon';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSellyConfig } from 'ng-selly.config';
import { FileUploadWithCredentialService } from './file-upload-with-credential.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiUploadVouchersService } from '../api-upload-vouchers.service';
import { pipe } from 'rxjs';


@Component({
  selector: '[affinity-upload-octo-file]',
  template: require('./affinity-upload-octo-file.html'),
  styles: [require('./affinity-upload-octo-file.scss')],
  providers: [
    NgSellyConfig,
    {provide: FileUploadService, useClass: FileUploadWithCredentialService},

  ]
})
export class AffinityUploadOctoFileComponent implements OnInit {

  roles = [];
  formGroup: FormGroup;
  opts: FileUploadOptions = {
    url: ''
  };
  isVisibleSuccess = false;
  isVisibleError = false;
  successStatus: string;
  successMessage: string;
  infoStatus: string;
  infoMessage: string;
  loading = false;

  mapFile = {};

  constructor(
    private formBuilder: FormBuilder,
    // private spinnerController: SpinnerController,
    private config: NgSellyConfig,
    private client: HttpClient,
    private uploadVoucher: ApiUploadVouchersService
  ) {
  }

  ngOnInit() {
    let data = sessionStorage.getItem('userState');
    let jsonData = JSON.parse(data);
    this.roles = jsonData.attributes.roles;
    this.formGroup = this.formBuilder.group({

      'octoFile': new FormControl('', Validators.required),
      'octoFileName': new FormControl('', Validators.required),
      'octoFileBlob': new FormControl('', Validators.required)

    });
  }

  selectFile(formFieldName, files: Array<any>) {
    if (files.length > 1) {
      this.formGroup.reset();
      alert('Selezionare un solo file');
    } else if (files.length === 0) {
      delete this.mapFile[formFieldName];
      this.formGroup.get(formFieldName).reset();
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imgBlob = new Blob([reader.result], {
          type: files[0].type
        });
        // imgBlob è il mio file
        this.mapFile[formFieldName] = files[0];
        this.formGroup.get('octoFileBlob').setValue(imgBlob);
        this.formGroup.get(formFieldName).setValue(files[0].name);
      };
      reader.readAsArrayBuffer(files[0]);
    }
  }

  onSubmit() {
    this.loading = true;
    const formModel = this.formGroup.value;
    const file = formModel.octoFileBlob;
    const fileName = formModel.octoFileName;
    let formData = new FormData();
    formData.append('file', file, fileName);

    this.uploadVoucher.uploadVocuhers(formData).subscribe((response) => {

      if (response.status === 200) {
        console.log('response received is ', response);
        this.loading = false;
        this.isVisibleSuccess = true;
        this.successStatus = response.statusText;
        this.successMessage = 'Il file è stato correttamente caricato';
      } else {
        console.log('response received is ', response);
        this.loading = false;
        this.isVisibleError = true;
        this.infoStatus = response.statusText;
        this.infoMessage = 'Non è stato possibile caricare il file';
      }
    }, (errorResponse: HttpErrorResponse) => {
      console.log('error ', errorResponse);
      this.loading = false;
      this.isVisibleError = true;
      this.infoStatus = errorResponse.name;
      this.infoMessage = errorResponse.message;
    });
    this.formGroup.reset();
  }

  closeAlert() {
    this.isVisibleSuccess = false;
    this.isVisibleError = false;
  }
}
