import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'fw-icon',
  template: require('./icon.html'),
  styles: [ require('./icon.scss') ]
})
export class IconComponent {
  @Input()
  size: string;

  @Input()
  iconName: string;

  @Input()
  color: string = 'black';

  @Input()
  baseName: string = 'ux-a-icons.svg#ux-a-icon-';

  @Input()
  inCircle: boolean = false;

  getClass() {
    let ret = {};
    if (this.inCircle)
      _.set(ret, 'inCircle', true);

    _.set(ret, 'uxa-a-icon', true);
    _.set(ret, 'uxa-a-icon--size-' + this.size, true);
    _.set(ret, 'uxa-a-icon--color-' + this.color, true);
    _.set(ret, 'inCircle', this.inCircle);
    return ret;
  }
}
