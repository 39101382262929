import { Injectable } from '@angular/core';
import { NgSellyBaseConfig } from './ng-selly-base.config';

@Injectable()
export class NgSellyConfig extends NgSellyBaseConfig {

  constructor() {
    super();
    this.apiServer = `https://ng-selly-server.helvetia.it/`;
    this.operatorLoginUrl = `auth/4Operator`;
    this.liquidatoreLoginUrl = `auth/4Operator`;
    this.apiRegister = 'https://api.selly.k8s-helvetia.ddns.net/api/v2/auth/btc/'
  }
}
