import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundPageComponent } from '@helvetia-italia/ng-selly-components/src/lib/not-found-page/not-found-page.component';
import { AuthComponent, AuthSecurityComponent, LoginComponent } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/auth/user.service';
import { AgentsGuard, LiquidatoreGuard, AffinityOperatorGuard, ScriptsGuard } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/auth/agents.guard';
import { HeaderComponent } from './selly-dashboard/header/header.component';

const appRoutes: Routes = [
  {
    path: 'home',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./affinity-select-page/affinity-select-page.module')['AffinitySelectPageModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AgentsGuard],
  },
  {
    path: 'dashboard-affinity',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./affinity-user-list-module/affinity-user-list.module')['AffinityUserListModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AffinityOperatorGuard],
  },
  {
    path: 'liquidatore',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./liquidatore-module/selly-liquidatore.module')['SellyLiquidatoreModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [LiquidatoreGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard-affinity(header:header)',
    pathMatch: 'full'
  },
  {
    path: 'liquidatore/results(header:header)',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./liquidatore-module/selly-liquidatore.module')['SellyLiquidatoreModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
  },
  {
    path: 'upload',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./affinity-upload-octo-file/affinity-octo-upload.module')['AffinityOctoUploadModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AgentsGuard],
  },
  {
    path: 'edit-mail',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./edit-mail-template/edit-mail-template.module')['EditMailTemplateModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AgentsGuard],
    // canActivate: [LiquidatoreGuard],
  },
  {
    path: 'reports/:id',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./reports/reports.module')['ReportsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    // canActivate: [AgentsGuard],
  },
  {
    path: 'report/lead-acquisition',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./lead-acquisition-dashboard/lead-acquisition-user-list.module')['LeadAcquisitionUserListModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    // canActivate: [AgentsGuard],
  },
  {
    path: 'support-scripts/:id',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./support-scripts/support-scripts.module')['SupportScriptsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [ScriptsGuard]
  },
  {
    path: 'support-scripts/uploadWinnerMarketingCampaign',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./support-scripts/support-scripts.module')['SupportScriptsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [ScriptsGuard]
  },
  {
    path: 'uploadWinnerMarketingCampaign',
    redirectTo: '/support-scripts/uploadWinnerMarketingCampaign',
    pathMatch: 'full',
  },
  {
    path: 'affinity-quotes',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./affinity-quotes/affinity-quotes.module')['AffinityQuotesModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    // canActivate: [AgentsGuard],
  },
  {
    path: 'auth',
    component: AuthComponent,
  }, {
    path: 'auth/security',
    component: AuthSecurityComponent,
  }, {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: '404',
    component: NotFoundPageComponent,
    data: {
      urlRoot: '/'
    }
  },
  {
    path: '**',
    redirectTo: '/404(header:header)',
  },
  {
    path: 'header',
    component: HeaderComponent,
    outlet: 'header'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class SellyDashboardRoutes {
  constructor() {
  }
}
