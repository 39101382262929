const moment = require('moment');
// require('dotenv').config();
const request = require('request');
const _ = require('lodash');

export const daily = () => {
  return {from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')};
};

export const weekly = () => {
  // from, to ...
  // tslint:disable-next-line:variable-name
  let from_date = moment().startOf('isoWeek');
  // tslint:disable-next-line:variable-name
  let to_date = moment().endOf('isoWeek');

  let days = [];
  let day = from_date;
  while (day <= to_date) {
    days.push(day.toDate());
    day = day.clone().add(1, 'd');
  }
  return {from: moment(days[0]).format('YYYY-MM-DD'), to: moment(days[6]).format('YYYY-MM-DD')};
};

export const monthly = () => {
  // tslint:disable-next-line:variable-name
  let from_date = moment().startOf('month');
  // tslint:disable-next-line:variable-name
  let to_date = moment().endOf('month');
  return {from: moment(from_date).format('YYYY-MM-DD'), to: moment(to_date).format('YYYY-MM-DD')};
};

export const fromBeginningtoToday = () => {
  // tslint:disable-next-line:variable-name
  let from_date = moment('2018-09-01');
  let today = moment();
  return {from: moment(from_date).format('YYYY-MM-DD'), to: moment(today).format('YYYY-MM-DD')};
};

export const buildURLConnection = (endpoint, filters) => {
  if (!endpoint.includes('https'))
    endpoint = 'http://' + endpoint;
  return endpoint + filters;

   // if (env.toLowerCase().trim() === 'devel')
   // return 'https://' + process.env.DEVEL + filters;
   // else if (env.toLowerCase().trim() === 'pprod')
   // return 'https://' + process.env.PPROD + filters;
   // else if (env.toLowerCase().trim() === 'prod')
   // return 'https://' + process.env.PROD + filters;
};

export const retrieveType = (productCode, productNode, PRODUCT_TYPE) => {
  if (!productCode)
    return null;
  let type = null;
  if (productCode.startsWith('EASYTRAVEL')) {
    type = isEasyTravelBTC(productNode) ? PRODUCT_TYPE.EASYTRAVEL_BTC : PRODUCT_TYPE.EASYTRAVEL_4AGENTS;
  } else {
    type = productCode;
  }
  return type;
};

export const isEasyTravelBTC = (productNode) => {
  // EASYTRAVEL-BTC —> il prodotto ha lo stesso codice: EASYTRAVEL, ma la differenzi con il campo “productNode”: 9000 è BTC altrimenti è 4AGENTS
  if (productNode && (productNode === '09000' || productNode === '9000'))
    return true;
  return false;
};

export const buildQueryFilters = (dates) => {
  let ff = '';
  if (dates && dates !== null)
    ff = '?' + 'startkey=\"' + dates.from + '\"&endkey=\"' + dates.to + '\"';
  return ff;
};

export const checkCmdsLine = (cmds) => {
  if (cmds[2] === undefined || cmds[3] === undefined)
    return false;
  return true;
};

export const findFromDB = async (db, sourceDbPartialUrl) => {
  let resultObj;
  const partialUrl = sourceDbPartialUrl;

  let url = partialUrl + `/${db}/_all_docs?include_docs=true`;

  return new Promise((resolve, reject) => {
    request.get({
      headers: {
        'Content-Type': 'application/json'
      },
      url: url,
      json: true,
    }, function (err, response, body) {
      if (err) {
        resultObj = {
          level: 'error',
          message: 'Error from db',
          data: err
        };
        return reject(resultObj);
      } else {
        let doc = _.get(body, 'rows', {}).map((e) => e.doc);
        resultObj = {
          level: 'info',
          message: 'Values from db',
          data: doc
        };
        return resolve(resultObj);
      }
    }).on('error', (err) => {
      resultObj = {
        level: 'error',
        message: 'Network Error while connecting to db',
        data: err
      };
      return reject(resultObj);
    });
  });
};

export async function updateDb(db, sourceDbPartialUrl, items) {
  const partialUrl = sourceDbPartialUrl;
  const url = partialUrl + `/${db}/_bulk_docs`;

  items = items.map((e) => {
    delete e._id;
    delete e._rev;
    return e;
  });

  let payload = {docs: items};

  return new Promise((resolve, reject) => {
    request.post({
      headers: {
        'Content-Type': 'application/json'
      },
      url: url,
      json: true,
      body: payload
    }, function (err, response, body) {
      if (err !== null) {
        console.error('UPDATING POLICY STATUS', err);
        reject(err);
      }
      console.log('DONE UPDATE!');
      resolve('OK');
    });
  });
}

export async function deleteDb(db, sourceDbPartialUrl, items) {
  const partialUrl = sourceDbPartialUrl;
  const url = partialUrl + `/${db}/_bulk_docs`;

  items = items.map((e) => {
    return {_id: e._id, _rev: e._rev, _deleted: true};
  });

  let payload = {docs: items};

  return new Promise((resolve, reject) => {
    request.post({
      headers: {
        'Content-Type': 'application/json'
      },
      url: url,
      json: true,
      body: payload
    }, function (err, response, body) {
      if (err !== null) {
        console.error('UPDATING POLICY STATUS', err);
        reject(err);
      }
      console.log('DONE DELETE!');
      resolve('OK');
    });
  });
}

