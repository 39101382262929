import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface InfoBoxConfig {
  title?: string;
  html: string;
}

@Injectable()
export class InfoBoxController {

  change: Subject<InfoBoxConfig> = new Subject<InfoBoxConfig>();

  show(infoBoxConfig?: InfoBoxConfig) {
    this.change.next(infoBoxConfig);
  }

}
