import { Component, OnInit } from '@angular/core';
import { InsuranceProduct } from '@helvetia-italia/ng-selly-lib-operator-dashboard/src/lib/selly-dashboard-shared-module/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductListService } from '../../remote-service-module/product-list/product-list.mock-service';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { HeadlineService } from '../../remote-service-module/headline/headline.service';

@Component({
  selector: 'fw-header',
  template: require('./header.html'),
  styles: [require('./header.scss')],
})
export class HeaderComponent {

  productInfo: InsuranceProduct;
  productCode: string;
  description: string;

  constructor(
    private location: Location,
    private headlineService: HeadlineService,
    activatedRoute: ActivatedRoute,
    productListService: ProductListService) {

    activatedRoute.params.subscribe((params: Params) => {
      console.log('params', params);
      this.productCode = params['productCode'];
      if (!_.isNil(this.productCode)) {
        productListService.getByCode(this.productCode).then((productInfo: InsuranceProduct) => {
          this.productInfo = productInfo;
          this.description = productInfo.description;
        });
      }
    });

    this.headlineService.getObserver().subscribe((newValue: string) => {
      this.description = newValue;
    });
  }

  back() {
    this.location.back();
    this.headlineService.setValue('');
  }
}
